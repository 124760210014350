<template>
  <div>
    <P12></P12>
    <div
      class="level page-pad mb-0 has-background-kin"
      style="
        padding-bottom: 0.25rem;
        padding-top: 0.3rem;
        border-bottom: 0px solid #83c8f3;
      "
    >
      <div class="level-left">
        <div class="level-item m-0">
          <b-tooltip
            type="is-light"
            :label="perms.myAccount ? 'Otwórz menu SZOK': 'Jesteś obecnie w systemie SZOK :)'"
            position="is-right"
            :delay="900"
            :animated="false"
          >
            <div
              @click="toggleSidebar"
              id="logoButton"
              class="pr-4 pl-2 hand"
              style="padding-top: 2px; padding-bottom: 0px"
            >
              <!-- <img src="logo.svg" style="height: 1.rem;" /> -->
              <b-icon
                size="is-medium"
                icon="heart-pulse"
                type="is-primelight"
              ></b-icon>
            </div>
          </b-tooltip>
        </div>
        <div class="level-item m-0">
          <b-tag
            type="is-danger"
            class="mr-3"
            v-if="runMode == 'T'"
          >
            <b>Serwer T1</b>
          </b-tag>
          <b-tag
            type="is-warning"
            class="mr-3"
            v-if="runMode == 'K'"
          >
            <b>Serwer KLON</b>
          </b-tag>
          <b-tag
            type="is-danger"
            class="mr-3 handpointer"
            v-if="loginBlock"
          >
            <b>BLOKADA LOGOWANIA! ಠ_ಠ</b>
          </b-tag>
        </div>
        <div class="level-item">
          <div
            v-if="routerLoading"
            style="padding-bottom: 0.1rem"
          >
            <span class="has-text-weight-semibold">
              <b-icon
                icon="timelapse"
                type="is-orange"
                style="position:absolute;"
              ></b-icon>
              <span
                class="has-text-light mr-2"
                style="margin-left:2.1rem;"
              >Trwa ładowanie:</span>
              <span class="has-text-orange">{{ routerLabel }}</span>
            </span>
          </div>
          <div
            v-else
            class="has-text-weight-semibold has-text-primelight"
            style="padding-bottom: 0.1rem"
          >
            <span>{{ currentRouteName }}</span>
          </div>
        </div>
      </div>
      <div class="level-right mt-0">
        <div class="level-item">
          <!-- <b-tooltip class="pr-05" label="Tu będą powiadomienia" position="is-bottom">
            <b-icon icon="bell" type="is-light" data-badge="1" class="has-badge-info"></b-icon>
          </b-tooltip>-->
          <div v-if="perms.myAccount">
            <IdentityPanel />
          </div>
          <div v-else>
            <IdentityPanel />
          </div>
        </div>

        <div
          class="level-item"
          v-if="perms.ownDomainSwitch"
        >
          <b-field>
            <b-select
              @input="changeMyDomain"
              size="is-small"
              v-model="domainSelected"
            >
              <option
                v-for="dom in myDomains"
                :key="dom"
                :value="dom"
              >{{mGetDomainDesc(dom)}}</option>
            </b-select>
          </b-field>
        </div>

        <!-- <div class="level-item">
         <ScheduleDomainSelector></ScheduleDomainSelector>
        </div>-->

        <div class="level-item">
          <b-tooltip
            v-if="isServiceTime"
            type="is-danger"
            multilined
            class="mr-1"
            label="Jesteś zalogowany do systemu w porze nocnej. W tym czasie mogą być prowadzone prace serwisowe, a dostęp do niektórych funkcji systemu może być ograniczony."
            position="is-bottom"
          >
            <b-button
              outlined
              type="is-danger"
              size="is-small"
              icon-left="weather-night"
            ></b-button>
          </b-tooltip>

          <MySchedule class="mr-1"></MySchedule>
          <TalkAlerts></TalkAlerts>
          <!-- <SzokTalk
            class="mr-1"
            @show="chatOpen = true"
          ></SzokTalk> -->
          <!-- <b-button
            outlined
            @click="chatOpen = true"
            type="is-primelight"
            size="is-small"
            icon-left="chat"
            class="mr-1"
          >
            <span v-if="chatMessages.length > 0">{{chatMessages.length}}</span>
          </b-button>-->
          <SimpleKeyboard class="mr-1" />
          <b-dropdown
            class="mr-1"
            aria-role="list"
            position="is-bottom-left"
          >
            <b-tooltip
              label="Szybki dostęp i pomoc"
              position="is-bottom"
              type="is-dark"
              slot="trigger"
              :animated="false"
              :delay="500"
            >
              <b-button
                type="is-primelight"
                outlined
                size="is-small"
                icon-left="help-circle"
              ></b-button>
            </b-tooltip>
            <b-dropdown-item
              aria-role="listitem"
              @click="proceedTo('user-manual')"
            >
              <b-icon
                icon="book-open"
                size="is-small"
                class="menu-larger-icon"
              ></b-icon>Instrukcja obsługi
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="proceedTo('elearning')"
            >
              <b-icon
                icon="book-open"
                size="is-small"
                class="menu-larger-icon"
              ></b-icon>E-Learning
            </b-dropdown-item>
            <b-dropdown-item
              paddingless
              custom
              aria-role="listitem"
            >
              <CrmReportForm class="mr-1"></CrmReportForm>
            </b-dropdown-item>
            <b-dropdown-item
              paddingless
              custom
              aria-role="listitem"
            >
              <UserFeedback
                :hoverable="true"
                :listmode="true"
                :target="$options.name"
                :light="true"
              ></UserFeedback>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            class="ml-0 no-top-pad-dropdown mr-1"
            aria-role="list"
            position="is-bottom-left"
          >
            <b-tooltip
              label="Ustawienia"
              position="is-bottom"
              slot="trigger"
              :animated="false"
              :delay="900"
              type="is-dark"
            >
              <b-button
                type="is-primelight"
                outlined
                size="is-small"
                icon-left="cog"
              >Ustawienia</b-button>
            </b-tooltip>

            <b-dropdown-item
              custom
              paddingless
              aria-role="listitem"
            >
              <FacilitySelector></FacilitySelector>
            </b-dropdown-item>
            <hr class="dropdown-divider" />
            <b-dropdown-item
              aria-role="listitem"
              @click="proceedTo('my-account')"
            >
              <b-icon
                icon="account-circle"
                size="is-small"
                class="menu-larger-icon"
              ></b-icon>Moje konto pracownika
            </b-dropdown-item>
            <b-dropdown-item
              v-if="perms.admin"
              aria-role="listitem"
              @click="cloneToken"
            >
              <b-icon
                icon="key"
                size="is-small"
                class="menu-larger-icon"
              ></b-icon>[Admin] Token
            </b-dropdown-item>

            <b-dropdown-item
              paddingless
              aria-role="listitem"
              custom
            >
              <LinkConnector class="mr-1"></LinkConnector>
            </b-dropdown-item>
            <b-dropdown-item
              custom
              aria-role="listitem"
              class="p-0"
              v-if="perms.subadmin"
            >
              <b-dropdown
                aria-role="dialog"
                position="is-bottom-left"
                class="w-100"
              >
                <a
                  style="width: 20rem"
                  @click="isComponentModalActive = true"
                  class="dropdown-item"
                  slot="trigger"
                >
                  <b-icon
                    icon="account-switch-outline"
                    size="is-small"
                    class="menu-larger-icon"
                  ></b-icon>Identyfikacja
                </a>

                <div class="pt-3 pb-3 pr-5 pl-5">
                  <b-field
                    label="Udawany pacjent"
                    label-position="on-border"
                    style="width: 20rem"
                  >
                    <b-select
                      expanded
                      placeholder="Udawany pacjent"
                      v-model="patient"
                      @input="
                        patientPretend();
                        getPatientDiagnoses();
                        getPatientAppointments();
                      "
                    >
                      <option
                        v-for="item in patients"
                        :key="item.value"
                        :value="item"
                      >{{ item.lastName + " " + item.firstName }}</option>
                    </b-select>
                  </b-field>
                  <b-field
                    label="Udawany lekarz"
                    label-position="on-border"
                  >
                    <b-autocomplete
                      v-model="searchEmployee"
                      :data="filteredEmployee"
                      open-on-focus
                      clearable
                      placeholder="Wybierz pracownika"
                      field="name"
                      :keep-first="true"
                      @select="pretend"
                    >
                      <template slot-scope="props">{{ props.option.name }}</template>
                    </b-autocomplete>
                  </b-field>
                </div>
              </b-dropdown>
            </b-dropdown-item>
            <b-dropdown-item
              custom
              paddingless
              aria-role="listitem"
            >
              <NavPLC></NavPLC>
            </b-dropdown-item>
            <b-dropdown-item
              custom
              paddingless
              aria-role="listitem"
            >
              <NavP12></NavP12>
            </b-dropdown-item>
            <hr class="dropdown-divider" />
            <b-dropdown-item
              aria-role="listitem"
              @click="fullscreeen"
            >
              <b-icon
                icon="resize"
                size="is-small"
                class="menu-larger-icon"
              ></b-icon>Tryb pełnoekranowy (F11)
            </b-dropdown-item>
            <b-dropdown-item
              custom
              aria-role="listitem"
            >
              <p class="mb-3">
                <b-icon
                  icon="book-open"
                  size="is-small"
                  class="menu-larger-icon"
                ></b-icon>Rozmiar czcionki
              </p>
              <div>
                <FontSizeSelector></FontSizeSelector>
              </div>
            </b-dropdown-item>
          </b-dropdown>
          <b-tooltip
            :label="t('common.logOut')"
            position="is-bottom"
            type="is-danger"
            class="hand"
            :delay="700"
            :animated="false"
          >
            <b-button
              @click="logout()"
              type="is-danger"
              outlined
              size="is-small"
              icon-right="exit-to-app"
            >
              <span
                class="is-size-7"
                v-if="sessionEndsIn > 60"
              >{{ Math.round(sessionEndsIn / 60) }}m</span>
              <span
                class="is-size-7"
                v-else
              >({{ sessionEndsIn }}s)</span>
            </b-button>
          </b-tooltip>
        </div>
      </div>
    </div>
    <!-- 
    <b-progress
      :rounded="false"
      v-if="routerLoading"
      type="is-primary"
      style="height:0.3rem;margin-bottom:-0.3rem;"
    ></b-progress>-->

    <div
      class="meter animate"
      v-if="routerLoading"
    >
      <span style="width: 100%">
        <span></span>
      </span>
    </div>

    <b-sidebar
      type="is-kin"
      :fullheight="true"
      :fullwidth="false"
      :overlay="true"
      :open.sync="open"
      class="main-sidebar"
      scroll="keep"
    >
      <div
        class="columns is-mobile is-variable is-0"
        style="z-index: 10"
      >
        <div class="column">
          <b-menu
            class="slim-menu sidebar-container pb-1 pl-2 pt-4"
            :activable="false"
            :accordion="false"
          >
            <!-- ADMIN -->
            <b-menu-list
              label="Administracja"
              v-if="perms.admin"
            >
              <b-menu-item
                icon="alert"
                label="Aktywni użytkownicy (UAC)"
                @click="proceedTo('admin-uac')"
              ></b-menu-item>
              <b-menu-item
                icon="alert-rhombus"
                v-if="perms.admin"
              >
                <template slot="label">
                  Kontrola systemu
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  icon="alert-rhombus"
                  label="Autoryzacja kont"
                  @click="proceedTo('admin-accounts')"
                ></b-menu-item>
                <b-menu-item
                  icon="lock"
                  label="Blokady pacjentów (PLC)"
                  @click="proceedTo('admin-plc')"
                ></b-menu-item>
                <b-menu-item
                  icon="printer"
                  label="Hub/Link"
                  @click="proceedTo('admin-hub')"
                ></b-menu-item>
                <b-menu-item
                  icon="car-brake-alert"
                  label="Crash Log"
                  @click="proceedTo('crash-log')"
                ></b-menu-item>
                <b-menu-item
                  icon="space-invaders"
                  label="Feedback"
                  @click="proceedTo('admin-feedbacks')"
                ></b-menu-item>
                <b-menu-item
                  icon="pill"
                  label="Kontrola leków"
                  @click="proceedTo('drug-control')"
                ></b-menu-item>
                <b-menu-item
                  icon="ghost"
                  label="Błędy API"
                  @click="proceedTo('admin-api-errors')"
                ></b-menu-item>

                <b-menu-item
                  icon="theme-light-dark"
                  label="Nocne Marki"
                  @click="proceedTo('employee-night')"
                ></b-menu-item>
              </b-menu-item>

              <b-menu-item
                icon="package-variant"
                v-if="perms.admin"
              >
                <template slot="label">
                  Placówki i wyposażenie
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  icon="home"
                  label="Placówki"
                  @click="proceedTo('facilities')"
                ></b-menu-item>
                <b-menu-item
                  icon="hospital-building"
                  label="Nieruchomości"
                  @click="proceedTo('buildings')"
                ></b-menu-item>
                <b-menu-item
                  icon="scale"
                  label="Urządzenia medyczne"
                  @click="proceedTo('medical-devices')"
                ></b-menu-item>
              </b-menu-item>

              <!-- <b-menu-item icon="flash-outline" label="Zgłoszenia" @click="proceedTo('admin-crm')"></b-menu-item> -->

              <!-- <b-menu-item
                icon="hexagon"
                label="Struktura"
                @click="proceedTo('structure')"
              ></b-menu-item>-->

              <b-menu-item
                icon="format-paint"
                v-if="perms.subadmin"
              >
                <template slot="label">
                  Prototypy
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  icon="rotate-3d"
                  label="Trzeci wymiar SZOKa"
                  @click="proceedTo('3d')"
                ></b-menu-item>
                <b-menu-item
                  icon="draw"
                  label="Swojskie ikony"
                  @click="proceedTo('e-signature')"
                ></b-menu-item>
                <b-menu-item
                  icon="bottle-tonic-plus-outline"
                  label="Koordynator leczenia"
                  @click="proceedTo('treatment-coordinator')"
                  v-if="perms.treatmentCoordination"
                ></b-menu-item>
                <b-menu-item
                  icon="map-marker-radius-outline"
                  label="Manager rejonu"
                  @click="proceedTo('manager-region')"
                  v-if="perms.managementRegion"
                ></b-menu-item>
                <b-menu-item
                  icon="home-group"
                  label="Koordynator klastra"
                  @click="proceedTo('manager-cluster')"
                  v-if="perms.managementCluster"
                ></b-menu-item>
              </b-menu-item>

              <b-menu-item
                icon="alien"
                label="Lista API"
                @click="proceedTo('admin-api-lib')"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list
              label="Kontrola leków"
              v-if="perms.drugControlEdit || perms.drugControlApprove"
            >
              <b-menu-item
                icon="pill"
                label="Kontrola leków"
                @click="proceedTo('drug-control')"
              ></b-menu-item>
            </b-menu-list>

            <!-- LAB -->

            <b-menu-list
              label="Laboratorium"
              v-if="
                perms.viralLaboratory ||
                perms.viralCollectionPoint ||
                perms.laboratoryRecive ||
                perms.cytologyAnalysis ||
                perms.officeMidwife
              "
            >
              <b-menu-item
                icon="account-plus"
                v-if="perms.viralLaboratory"
                label="Rejestracja zlecenia"
                @click="proceedTo('lab-registration')"
              ></b-menu-item>
              <b-menu-item
                icon="package-variant"
                v-if="perms.viralLaboratory"
                label="Punkt odbioru ogólny"
                size="is-small"
                @click="proceedTo('lab-cytology-recive')"
              ></b-menu-item>
              <!-- <b-menu-item
                label="Punkt odbioru mat. zakaźnego"
                @click="proceedTo('lab-recive')"
                icon="virus"
                v-if="perms.laboratoryRecive"
              ></b-menu-item>-->
              <b-menu-item
                v-if="perms.viralLaboratory"
                icon="human-female"
              >
                <template slot="label">
                  Cytologia
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <!-- <b-menu-item
                  icon="package-variant"
                  v-if="perms.viralLaboratory"
                  label="Odbiór"
                  size="is-small"
                  @click="proceedTo('lab-cytology-recive')"
                ></b-menu-item>-->
                <!-- <b-menu-item
                  icon="needle"
                  v-if="perms.officeMidwife"
                  label="Pobrania"
                  @click="proceedTo('lab-cytology')"
                ></b-menu-item>-->
                <b-menu-item
                  icon="format-color-fill"
                  v-if="perms.viralLaboratory"
                  label="Dystrybucja"
                  @click="proceedTo('lab-cytology-dirty')"
                ></b-menu-item>
                <b-menu-item
                  icon="scan-helper"
                  v-if="perms.admin"
                  label="Skanowanie preparatów"
                  @click="proceedTo('lab-cytology-scan')"
                ></b-menu-item>
                <b-menu-item
                  icon="home-analytics"
                  v-if="perms.viralLaboratory"
                  label="Analiza"
                  @click="proceedTo('lab-cytology-analysis')"
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                v-if="perms.viralLaboratory"
                icon="virus"
              >
                <template slot="label">
                  Wirusologia
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <!-- <b-menu-item
                  v-if="perms.viralCollectionPoint"
                  label="Pobrania epidemiologiczne"
                  @click="proceedTo('lab-covid')"
                ></b-menu-item>-->
                <!-- <b-menu-item
                  label="Odbiór"
                  @click="proceedTo('lab-recive')"
                  v-if="perms.laboratoryRecive"
                ></b-menu-item>-->
                <b-menu-item
                  label="Dystrybucja"
                  icon="format-color-fill"
                  @click="proceedTo('lab-dirty')"
                  v-if="perms.viralLaboratory"
                ></b-menu-item>
                <b-menu-item
                  label="Analiza"
                  icon="home-analytics"
                  @click="proceedTo('lab-clean')"
                  v-if="perms.viralLaboratory"
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                v-if="
                  perms.histopathologyCollectionPoint ||
                  perms.histopathologyPathologist ||
                  perms.histopathologyAdministrator
                "
                icon="virus"
              >
                <template slot="label">
                  Histopatologia
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  v-if="perms.histopathologyCollectionPoint"
                  label="Wysyłka"
                  icon="cube-send"
                  @click="proceedTo('lab-histpat-packages')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.histopathologyPathologist"
                  @click="proceedTo('lab-histpat-analysis')"
                  label="Ocena"
                  icon="home-analytics"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.histopathologyAdministrator"
                  label="eKsięga"
                  icon="notebook-check"
                  @click="proceedTo('lab-histpat-book')"
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                icon="notebook-check"
                label="Księga laboratoryjna"
                @click="proceedTo('lab-book')"
                v-if="
                  perms.viralLaboratory ||
                  perms.laboratoryManagement ||
                  perms.officeMidwife
                "
              ></b-menu-item>
              <b-menu-item
                icon="format-paint"
                label="Zarządzanie"
                @click="proceedTo('lab-management')"
                v-if="perms.laboratoryManagement"
              ></b-menu-item>
              <b-menu-item
                icon="format-paint"
                label="Podpis wyników"
                @click="proceedTo('lab-sign')"
                v-if="perms.viralLaboratory"
              ></b-menu-item>
              <b-menu-item
                icon="format-paint"
                label="Tabela fauli"
                @click="proceedTo('lab-foul')"
                v-if="perms.admin"
              ></b-menu-item>
            </b-menu-list>
            <!-- LAB MINI -->
            <b-menu-list
              label="Laboratorium Mini"
              v-if="
                perms.labMiniCytology ||
                perms.labMiniCytologyAssistant ||
                perms.labMiniCytologyAdministration
              "
            >
              <b-menu-item
                icon="account-plus"
                v-if="
                  perms.labMiniCytologyAdministration ||
                  perms.labMiniCytologyAssistant
                "
                label="Rejestracja zlecenia"
                @click="proceedTo('lab-mini-registration')"
              ></b-menu-item>
              <b-menu-item
                icon="human-female"
                v-if="perms.labMiniCytology || perms.labMiniCytologyAssistant"
                label="Cytologia - analiza"
                @click="proceedTo('lab-mini-cytology-analysis')"
              ></b-menu-item>
              <b-menu-item
                icon="format-paint"
                label="Zarządzanie"
                @click="proceedTo('lab-mini-management')"
                v-if="
                  perms.labMiniCytologyAssistant ||
                  perms.labMiniCytologyAdministration
                "
              ></b-menu-item>
            </b-menu-list>
            <!-- MANAGEMENT -->
            <b-menu-list
              v-if="
                perms.subadmin ||
                perms.humanResources ||
                perms.worktimeManagement ||
                perms.administration ||
                perms.administrationContract ||
                perms.administrationStructure ||
                perms.eReferralBook ||
                perms.prices ||
                perms.administrationPopulationManagement ||
                perms.projectManagement
              "
              label="Zarządzanie"
            >
              <b-menu-item
                icon="alien"
                v-if="perms.subadmin || perms.humanResources"
              >
                <template slot="label">
                  Kadry
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  icon="alien"
                  v-if="perms.humanResources"
                  label="Lista pracowników"
                  @click="proceedTo('employee-list')"
                ></b-menu-item>
                <b-menu-item
                  icon="plus-thick"
                  v-if="perms.humanResources"
                  label="Nowy pracownik"
                  @click="proceedTo('employee-creator')"
                ></b-menu-item>
              </b-menu-item>
              <!-- <b-menu-item
                icon="calendar"
                v-if="perms.worktimeManagement"
                label="Terminarze STARE"
                @click="proceedTo('worktime-management')"
              ></b-menu-item>-->
              <b-menu-item
                icon="book"
                v-if="perms.admin"
                label="E-księga wizyt"
                @click="proceedTo('appointment-book')"
              ></b-menu-item>
              <b-menu-item
                icon="book"
                v-if="perms.admin"
                label="E-księga rezerwacji wizyt"
                @click="proceedTo('appointment-book-reservations')"
              ></b-menu-item>
              <b-menu-item
                icon="book"
                v-if="perms.eReferralBook"
                label="Księga e-skierowań"
                @click="proceedTo('appointment-ereferral')"
              ></b-menu-item>
              <b-menu-item
                icon="format-paint"
                label="Księga biletów"
                @click="proceedTo('tickets-book')"
                v-if="perms.admin"
              ></b-menu-item>
              <!-- <b-menu-item
                icon="book"
                label="Badania SOLAB"
                @click="proceedTo('solab-examinations')"
                v-if="perms.admin"
              ></b-menu-item>-->
              <b-menu-item
                icon="file-document"
                label="Administracja"
                @click="proceedTo('company-structures')"
                v-if="
                  perms.subadmin ||
                  perms.administration ||
                  perms.administrationContract ||
                  perms.administrationStructure
                "
              ></b-menu-item>
              <b-menu-item
                icon="home"
                label="Kontrahenci medyczni"
                @click="proceedTo('subjects-med')"
                v-if="perms.subadmin || perms.administration"
              ></b-menu-item>
              <b-menu-item
                icon="cart-plus"
                label="Cenniki"
                @click="proceedTo('prices')"
                v-if="perms.subadmin"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.subadmin"
                icon="cogs"
                label="Typy badań i świadczeń"
                @click="proceedTo('system-configuration')"
              ></b-menu-item>
              <!-- <b-menu-item
                icon="clipboard"
                label="Raporty LIOCZ"
                @click="proceedTo('liocz-list')"
                v-if="perms.lioczCreate"
              ></b-menu-item>-->
              <b-menu-item
                icon="alert-rhombus"
                v-if="perms.nfzSettlements"
              >
                <template slot="label">
                  Rozliczenia NFZ
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  icon="calculator"
                  label="Panel główny"
                  @click="proceedTo('settlements-panel')"
                ></b-menu-item>
                <b-menu-item
                  icon="alert"
                  label="Deklaracje"
                  @click="proceedTo('dekle-list')"
                ></b-menu-item>
                <b-menu-item
                  icon="clipboard"
                  label="Raporty SWX"
                  @click="proceedTo('swx-list')"
                ></b-menu-item>
                <b-menu-item
                  icon="heart-broken"
                  label="Rozliczenia inne"
                  @click="proceedTo('nfz-dashboard')"
                  v-if="
                    perms.subadmin ||
                    perms.administration ||
                    perms.administrationContract ||
                    perms.administrationStructure
                  "
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                icon="alert-rhombus"
                v-if="perms.nfzSettlements"
              >
                <template slot="label">
                  Rozliczenia komercja
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  icon="calculator"
                  label="Panel główny"
                  @click="proceedTo('settlements-commercial')"
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                v-if="perms.projectManagement"
                icon="microsoft-teams"
                label="Zarządzanie projektami"
                @click="proceedTo('projects-management')"
              ></b-menu-item>
              <b-tooltip
                label="Realizacje programów populacyjnych"
                v-if="perms.administrationPopulationManagement"
              >
                <b-menu-item
                  icon="exclamation"
                  label="Realizacje programów pop."
                  @click="proceedTo('projects-population-realizations-list')"
                ></b-menu-item>
              </b-tooltip>
            </b-menu-list>
            <!-- CRM -->
            <b-menu-list
              label="CRM"
              v-if="
                perms.crm ||
                perms.crmDigitmed ||
                perms.crmMaintenance ||
                perms.crmCustomerService ||
                perms.crmRodo ||
                perms.crmHr ||
                perms.crmIt
              "
            >
              <b-menu-item icon="flash">
                <template slot="label">
                  Zgłoszenia
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  icon="flash-outline"
                  label="Zgłoszenia IT"
                  @click="proceedTo('crm-it')"
                  v-if="perms.crm || perms.crmIt"
                ></b-menu-item>
                <b-menu-item
                  icon="ninja"
                  label="Zgłoszenia Digitmed"
                  @click="proceedTo('crm-digitmed')"
                  v-if="perms.crmDigitmed"
                ></b-menu-item>
                <b-menu-item
                  icon="hammer-wrench"
                  label="Zgłoszenia Utrzymanie"
                  @click="proceedTo('crm-maintenance')"
                  v-if="perms.crmMaintenance"
                ></b-menu-item>
                <b-menu-item
                  icon="comment-text-multiple-outline"
                  label="Zgłoszenia BOK"
                  @click="proceedTo('crm-customerService')"
                  v-if="perms.crmCustomerService"
                ></b-menu-item>
                <b-menu-item
                  icon="server-security"
                  label="Zgłoszenia RODO"
                  @click="proceedTo('crm-rodo')"
                  v-if="perms.crmRodo"
                ></b-menu-item>
                <b-menu-item
                  icon="email-search-outline"
                  label="Zgłoszenia HR"
                  @click="proceedTo('crm-hr')"
                  v-if="perms.crmHr"
                ></b-menu-item>
                <b-menu-item
                  icon="movie-check-outline"
                  label="Zgłoszenia Promocja"
                  @click="proceedTo('crm-promo')"
                  v-if="perms.crmPromo"
                ></b-menu-item>
                <b-menu-item
                  icon="flash-outline"
                  label="Zgłoszenia Pracownicy"
                  @click="proceedTo('crm-employees')"
                  v-if="perms.admin"
                ></b-menu-item>
              </b-menu-item>
            </b-menu-list>

            <!-- DIGITMED -->
            <b-menu-list
              label="DIGITMED"
              v-if="perms.subadmin"
            >
              <b-menu-item
                icon="smart-card-outline"
                label="Typy uprawnień"
                @click="proceedTo('privilege-editor')"
                v-if="perms.admin"
              ></b-menu-item>
              <b-menu-item
                icon="puzzle-edit"
                label="Edytor ICD 10/11"
                @click="proceedTo('icd-editor')"
                v-if="perms.admin"
              ></b-menu-item>
              <b-menu-item
                icon="cog-outline"
                label="Edytor konfiguracji V2"
                @click="proceedTo('config-editor')"
                v-if="perms.subadmin"
              ></b-menu-item>
              <!-- <b-menu-item
                icon="cog"
                label="Edytor konfiguracji (stary)"
                @click="proceedTo('digitmed-management-config')"
                v-if="perms.admin"
              ></b-menu-item>-->
              <b-menu-item
                icon="cellphone-charging"
                label="Ustawienia SMS"
                @click="proceedTo('digitmed-management-sms')"
                v-if="perms.admin"
              ></b-menu-item>
              <b-menu-item
                icon="briefcase-account"
                label="Klienci, usługi i moduły"
                @click="proceedTo('digitmed-management-clients')"
                v-if="perms.subadmin"
              ></b-menu-item>
              <b-menu-item
                icon="group"
                label="Grupy świadczeń/usług"
                @click="proceedTo('digitmed-management-groupers')"
                v-if="perms.subadmin"
              ></b-menu-item>
              <b-menu-item
                icon="pencil"
                label="Słowniki NFZ"
                @click="proceedTo('nfz-dictionaries')"
                v-if="perms.admin"
              ></b-menu-item>
              <b-menu-item
                icon="exclamation"
                label="Konfiguracja ścieżek"
                @click="proceedTo('projects-population-config')"
                v-if="perms.admin"
              ></b-menu-item>
              <b-menu-item
                icon="exclamation"
                label="Konfiguracja szablonów"
                @click="proceedTo('med-questions')"
                v-if="perms.admin || perms.subadmin"
              ></b-menu-item>
              <b-menu-item
                icon="exclamation"
                label="Konfiguracja zaleceń"
                @click="proceedTo('recommendations')"
                v-if="perms.admin"
              ></b-menu-item>
              <b-menu-item
                icon="excavator"
                label="Dawid"
                @click="proceedTo('test-dawid')"
                v-if="perms.subadmin"
              ></b-menu-item>
            </b-menu-list>
            <!-- ROZLICZENIA NFZ -->
            <b-menu-list
              label="Rozliczenia"
              v-if="
                perms.subadmin ||
                perms.administration ||
                perms.administrationContract ||
                perms.administrationStructure ||
                perms.nfzSettlements ||
                perms.settlementsDeclarations ||
                perms.settlementsServices ||
                perms.settlementsContractors ||
                perms.settlementsCashRegister ||
                perms.settlementsIKZ ||
                perms.ikzManagerEdit ||
                perms.humanResourcesStats ||
                perms.settlementsInvoicesPrint ||
                perms.settlementsSummary
              "
            >
              <b-menu-item
                icon="hand-heart"
                v-if="  perms.nfzSettlements "
              >
                <template slot="label">
                  Komunikaty NFZ
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  v-if="perms.nfzSettlements"
                  @click="proceedTo('swx-list')"
                  icon="medical-bag"
                  label="Statystyczne (SWX)"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.nfzSettlements"
                  @click="proceedTo('dekl-list')"
                  icon="account-circle"
                  label="Aktywne deklaracje (DEKL)"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.nfzSettlements"
                  @click="proceedTo('klx-list')"
                  icon="timer-sand"
                  label="Kolejki oczekujących (KLX)"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.nfzSettlements"
                  @click="proceedTo('zbpoz-list')"
                  icon="package-variant-closed"
                  label="Zbiorcze POZ (ZBPOZ)"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.nfzSettlements"
                  @click="proceedTo('settlements-invoices')"
                  icon="cash-register"
                  label="Rachunki i szablony (EFX/ZRZ)"
                ></b-menu-item>
              </b-menu-item>

              <b-menu-item
                v-if="perms.settlementsInvoicesPrint"
                @click="proceedTo('settlements-invoices-print')"
                icon="printer"
                label="Drukowanie rachunków NFZ"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.nfzSettlements"
                @click="proceedTo('settlements-umx-dashboard')"
                icon="text-box-multiple"
                label="Umowy NFZ"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.settlementsDeclarations"
                icon="account-group-outline"
                label="Deklaracje"
                @click="proceedTo('declarations')"
              ></b-menu-item>
              <b-menu-item
                icon="alert-rhombus"
                v-if="perms.settlementsServices"
              >
                <template slot="label">
                  Świadczenia
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  @click="proceedTo('settlements-umx-services')"
                  icon="format-list-checkbox"
                  label="Konfiguracja typów"
                ></b-menu-item>
                <b-menu-item
                  @click="proceedTo('settlements-services')"
                  icon="alert-rhombus"
                  label="Lista wykonanych świadczeń"
                ></b-menu-item>
                <b-menu-item
                  @click="proceedTo('appointment-book')"
                  icon="book"
                  label="Terminarz/Księga wizyt"
                ></b-menu-item>
              </b-menu-item>
              <!-- <b-menu-item
                @click="proceedTo('nfz-dashboard')"
                v-if="
                    perms.subadmin ||
                    perms.administration ||
                    perms.administrationContract ||
                    perms.administrationStructure
                  "
                icon="human-queue"
                label="Kolejki oczekujących"
              ></b-menu-item>-->
              <b-menu-item
                icon="plus-thick"
                v-if="perms.nfzSettlements || perms.humanResourcesStats"
                label="Statystyki czasu pracowników"
                @click="proceedTo('employee-stats')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.settlementsOrdersAndTickets"
                icon="ticket"
                label="Zlecenia/bilety"
                @click="proceedTo('tickets-book-new')"
              ></b-menu-item>

              <b-menu-item
                v-if="perms.settlementsReports"
                icon="clipboard"
                label="Zestawienia"
                @click="proceedTo('reports')"
              ></b-menu-item>

              <b-menu-item
                @click="proceedTo('summary-book')"
                icon="state-machine"
                v-if="perms.settlementsSummary"
                label="Stany realizacji"
              ></b-menu-item>
              <!-- <b-menu-item icon="alert-rhombus" v-if="perms.nfzSettlements">
                <template slot="label">
                  Modele rozliczeniowe
                  <b-icon icon="menu-down" size="is-small"></b-icon>
                </template>
                <b-menu-item disabled icon="space-invaders" label="POZ"></b-menu-item>
                <b-menu-item disabled icon="space-invaders" label="AOS"></b-menu-item>
                <b-menu-item disabled icon="space-invaders" label="Progi IKZ"></b-menu-item>
              </b-menu-item>-->
              <b-menu-item
                icon="home-city"
                v-if="perms.settlementsContractors"
              >
                <template slot="label">
                  Kontrahenci
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  @click="proceedTo('subjects-med')"
                  v-if="perms.subadmin || perms.administration"
                  icon="format-list-numbered"
                  label="Lista"
                ></b-menu-item>
                <b-menu-item
                  icon="cart-plus"
                  label="Cenniki"
                  @click="proceedTo('prices')"
                  v-if="perms.prices"
                ></b-menu-item>
                <b-menu-item
                  disabled
                  icon="file-document"
                  label="Rozliczenia"
                ></b-menu-item>
                <b-menu-item
                  disabled
                  icon="file-document-multiple"
                  label="Faktury"
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                v-if="perms.settlementsIKZ"
                @click="proceedTo('settlements-ikz-worker')"
                icon="clipboard-account"
                label="Przegląd IKZ pracownika"
              ></b-menu-item>
              <b-menu-item
                @click="proceedTo('settlements-ikz-manager')"
                icon="clipboard-list"
                label="Tabela usług IKZ"
                v-if="perms.ikzManagerEdit"
              ></b-menu-item>
              <b-menu-item
                icon="cash-register"
                v-if="perms.settlementsCashRegister"
              >
                <template slot="label">
                  Kasy
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  disabled
                  icon="format-list-text"
                  label="Lista kas"
                ></b-menu-item>
                <b-menu-item
                  disabled
                  icon="file-document-edit-outline"
                  label="Dokumenty księgowe"
                ></b-menu-item>
                <b-menu-item
                  disabled
                  @click="proceedTo('cash-registers')"
                  icon="bank-transfer"
                  label="Zarejestrowane transakcje"
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                icon="package"
                v-if="perms.subadmin"
              >
                <template slot="label">
                  Archiwum
                  <b-icon
                    icon="menu-down"
                    size="is-small"
                  ></b-icon>
                </template>
                <b-menu-item
                  disabled
                  icon="text-box-search"
                  label="Przeglądanie zapytań EWUŚ"
                ></b-menu-item>
                <b-menu-item
                  disabled
                  icon="text-box-search"
                  label="Przeglądanie EDM"
                ></b-menu-item>
                <b-menu-item
                  disabled
                  icon="text-box-search"
                  label="Przeglądanie ZM"
                ></b-menu-item>
              </b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
        <div class="column">
          <b-menu
            class="slim-menu pt-4 sidebar-container pb-1"
            :activable="false"
          >
            <!-- OTHER -->
            <b-menu-list label="SZOK">
              <b-menu-item
                icon="home"
                label="Strona główna"
                @click="proceedTo('home')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.myAccount"
                icon="account"
                label="Moje konto"
                @click="proceedTo('my-account')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.eLearning"
                icon="presentation"
                label="E-Learning"
                @click="proceedTo('elearning')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.patientPortal"
                icon="presentation"
                label="Portal pacjenta"
                @click="proceedTo('patientportal')"
              ></b-menu-item>
              <!-- <b-menu-item
                v-if="perms.mobi || perms.mobiLimited"
                icon="truck-fast"
                label="Centrum przesyłek"
                @click="proceedTo('mobi-old')"
              ></b-menu-item>-->
              <b-menu-item
                v-if="perms.mobi || perms.mobiLimited"
                icon="truck-fast"
                label="Centrum logistyki"
                @click="proceedTo('mobi')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.orders"
                icon="package-variant"
                label="Zamówienia"
                @click="proceedTo('orders')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.inventory"
                icon="sitemap"
                label="Inwentaryzacja"
                @click="proceedTo('inventory')"
              ></b-menu-item>
              <!-- <b-menu-item
                v-if="perms.myIkz"
                icon="package-variant"
                label="Test IKZ"
                @click="proceedTo('test-ikz')"
              ></b-menu-item>-->
              <b-menu-item
                v-if="perms.admin"
                icon="account-supervisor-outline"
                label="Test IKZ-zespół"
                @click="proceedTo('test-ikz-supervisor')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.zbpoz"
                @click="proceedTo('zbpoz-list')"
                icon="package-variant-closed"
                label="Zestawienia zbiorcze POZ"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.settlementsRegister"
                @click="proceedTo('settlements-services-only')"
                icon="alert-rhombus"
                label="Rejestr świadczeń"
              ></b-menu-item>
            </b-menu-list>

            <!-- REGISTRATION -->
            <b-menu-list
              label="Rejestracja"
              v-if="perms.registration"
            >
              <b-menu-item
                icon="account-circle"
                label="Rejestracja"
                @click="proceedTo('registration')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.admin"
                icon="phone-clock"
                label="Rejestracja - call center"
                @click="proceedTo('registration-callcenter')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.admin"
                icon="calendar-export"
                label="Rejestracja - zewnętrzne"
                @click="proceedTo('registration-external')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.admin"
                icon="calendar-import"
                label="Rejestracja - wizyty otwarte"
                @click="proceedTo('registration-open-visits')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.registrationEdekl"
                icon="at"
                label="Rejestracja - e-Deklaracje"
                @click="proceedTo('registration-edeclaration')"
              ></b-menu-item>
              <!-- <b-menu-item icon="phone" label="Call Center" @click="proceedTo('callcenter')"></b-menu-item> -->
              <!-- <b-menu-item icon="alien" label="Panel pacjentów" @click="proceedTo('patients')"></b-menu-item> -->
              <!-- <b-menu-item
                icon="calendar"
                label="Terminarze STARE"
                @click="proceedTo('worktime-management')"
              ></b-menu-item>-->
            </b-menu-list>

            <!-- ARCHIVE -->
            <b-menu-list
              label="Archiwum pacjenta"
              v-if="
                perms.patientArchive ||
                perms.patientArchiveExaminations ||
                perms.patientArchiveSummary ||
                perms.patientArchiveVisitsWorker ||
                perms.verifiedFilePanel
              "
            >
              <b-menu-item
                v-if="
                perms.patientArchive ||
                perms.patientArchiveExaminations ||
                perms.patientArchiveSummary ||
                perms.patientArchiveVisitsWorker
              "
                icon="archive"
                label="Archiwum pacjenta"
                @click="proceedTo('patient-archive')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.verifiedFilePanel"
                icon="file-question-outline"
                label="Weryfikacja plików pacjenta"
                @click="proceedTo('file-verified')"
              ></b-menu-item>
            </b-menu-list>

            <!-- OFFICES -->
            <b-menu-list label="Gabinety">
              <b-menu-item
                v-if="perms.officePoz"
                icon="hospital-box"
                label="Lekarz POZ"
                @click="proceedTo('poz')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.officeIPOM"
                icon="hospital-box"
                label="Konsultacje IPOM"
                @click="proceedTo('ipom-consultation')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.officeAssistant"
                icon="account-outline"
                label="Asystenci"
                @click="proceedTo('assistant')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.officeLaryngologist"
                icon="ear-hearing"
                label="Laryngolog"
                @click="proceedTo('laryngologist')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.officePharma"
                icon="pill"
                label="Farmaceuta"
                @click="proceedTo('pharma')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.officeNurse"
                icon="clipboard-account"
                label="Pielęgniarka"
                @click="proceedTo('nurse')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.officeMidwife"
                icon="gender-female"
                label="Położna"
                @click="proceedTo('midwife')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.officeRadiology"
                icon="radiology-box-outline"
                label="Radiolog"
                @click="proceedTo('ris')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.officeDermatologist"
                icon="face-recognition"
                label="Dermatolog"
                @click="proceedTo('dermatologist')"
              ></b-menu-item>

              <b-collapse
                :open="!perms.subadmin"
                position="is-bottom"
                aria-id="hiddenOffices"
              >
                <template #trigger="props">
                  <b-menu-item
                    :icon="!props.open ? 'menu-down' : 'menu-up'"
                    :label="!props.open ? 'Więcej' : 'Zwiń'"
                    aria-controls="hiddenOffices"
                  ></b-menu-item>
                </template>
                <b-menu-item
                  v-if="perms.officePediatrician"
                  icon="human-child"
                  label="Pediatra"
                  @click="proceedTo('pediatrician')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeEndoscopy"
                  icon="cable-data"
                  label="Endoskopia"
                  @click="proceedTo('endoscopy')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officePulmonologist"
                  icon="lungs"
                  label="Pulmonolog"
                  @click="proceedTo('pulmonologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officePsychiatrist"
                  icon="head-lightbulb"
                  label="Psychiatra"
                  @click="proceedTo('psychiatrist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officePsychologist"
                  icon="head-lightbulb"
                  label="Psycholog"
                  @click="proceedTo('psychologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeTherapist"
                  icon="head-lightbulb-outline"
                  label="Terapeuta"
                  @click="proceedTo('therapist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeTherapist"
                  icon="head-lightbulb-outline"
                  label="Terapia grupowa"
                  @click="proceedTo('therapist-group')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officePhysiotherapy"
                  icon="walk"
                  label="Fizjoterapeuta"
                  @click="proceedTo('physiotherapy')"
                ></b-menu-item>

                <b-menu-item
                  v-if="perms.officeRehabilitation"
                  icon="run"
                  label="Rehabilitacja"
                  @click="proceedTo('rehabilitation')"
                ></b-menu-item>

                <b-menu-item
                  v-if="perms.officeCardiologist"
                  icon="heart-multiple"
                  label="Kardiolog"
                  @click="proceedTo('cardiologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeEndocrinologist"
                  icon="water-alert"
                  label="Endokrynolog"
                  @click="proceedTo('endocrinologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeGynecologist"
                  icon="gender-female"
                  label="Ginekolog"
                  @click="proceedTo('gynecologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeNeurologist"
                  icon="brain"
                  label="Neurolog"
                  @click="proceedTo('neurologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeRheumatologist"
                  icon="bone"
                  label="Reumatolog"
                  @click="proceedTo('rheumatologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeAllergist"
                  icon="peanut"
                  label="Alergolog"
                  @click="proceedTo('allergist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeSurgeonGeneral"
                  icon="box-cutter"
                  label="Chirurg ogólny"
                  @click="proceedTo('surgeon-general')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeSurgeonOncological"
                  icon="set-center"
                  label="Chirurg onkologiczny"
                  @click="proceedTo('surgeon-oncological')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeSurgeonTrauma"
                  icon="image-broken-variant"
                  label="Chirurg urazowy"
                  @click="proceedTo('surgeon-trauma')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeDiabetologist"
                  icon="diabetes"
                  label="Diabetolog"
                  @click="proceedTo('diabetologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeDietician"
                  icon="food-apple"
                  label="Dietetyk"
                  @click="proceedTo('dietician')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeParamedic"
                  icon="shield-alert"
                  label="Ratownik"
                  @click="proceedTo('paramedic')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeSpeechTherapist"
                  icon="account-voice"
                  label="Logopeda"
                  @click="proceedTo('speech-therapist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeUrologist"
                  icon="hospital-box"
                  label="Urolog"
                  @click="proceedTo('urologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeOrthopaedist"
                  icon="hospital-box"
                  label="Ortopeda"
                  @click="proceedTo('orthopaedist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeInternalDiseases"
                  icon="hospital-box"
                  label="Chorób wewnętrznych"
                  @click="proceedTo('internal-diseases')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeNephrologist"
                  icon="hospital-box"
                  label="Nefrolog"
                  @click="proceedTo('nephrologist')"
                ></b-menu-item>
                <b-menu-item
                  v-if="perms.officeOncologist"
                  icon="hospital-box"
                  label="Onkolog"
                  @click="proceedTo('oncologist')"
                ></b-menu-item>
              </b-collapse>
            </b-menu-list>
            <!-- OFFICES -->
            <b-menu-list
              label="Pracownie"
              v-if="
                  perms.workshopRehabilitation ||
                  perms.workshopCare ||
                  perms.workshopDiagnostics ||
                  perms.workshopRadiology ||
                  perms.radiologyAdministration ||
                  perms.workshopRadiologyWorktimeScheduler ||
                  perms.workshopRadiologyWorktimeManagement ||
                  perms.workshopRadiologyFullAccess ||
                  perms.workshopRadiologyReadOnly ||
                  perms.radiologyAdministrationBook ||
                  perms.radiologyAdministrationMOBI ||
                  perms.radiologyAdministrationMedDevicesFullAccess ||
                  perms.radiologyAdministrationMedDevicesReadOnly ||
                  perms.radiologyAdministrationStats
                "
            >
              <b-menu-item
                v-if="perms.workshopRehabilitation"
                icon="human-handsup"
                label="Rehabilitacja"
                @click="proceedTo('rehabilitation-workshop')"
              ></b-menu-item>
              <b-menu-item
                v-if="
                  perms.workshopRadiology ||
                  perms.radiologyAdministration ||
                  perms.workshopRadiologyWorktimeScheduler ||
                  perms.workshopRadiologyWorktimeManagement ||
                  perms.workshopRadiologyFullAccess ||
                  perms.workshopRadiologyReadOnly ||
                  perms.radiologyAdministrationBook ||
                  perms.radiologyAdministrationMOBI ||
                  perms.radiologyAdministrationMedDevicesFullAccess ||
                  perms.radiologyAdministrationMedDevicesReadOnly ||
                  perms.radiologyAdministrationStats
                "
                icon="radiology-box-outline"
                label="Radiologiczna"
                @click="proceedTo('ris-workshop')"
              ></b-menu-item>
              <b-menu-item
                v-if="
                  perms.radiologyAdministration ||
                  perms.workshopRadiologyWorktimeScheduler ||
                  perms.workshopRadiologyWorktimeManagement ||
                  perms.workshopRadiologyFullAccess ||
                  perms.radiologyAdministrationBook ||
                  perms.radiologyAdministrationMOBI ||
                  perms.radiologyAdministrationMedDevicesFullAccess ||
                  perms.radiologyAdministrationMedDevicesReadOnly ||
                  perms.radiologyAdministrationStats
                "
                icon="radiology-box-outline"
                label="Radiologiczna - zarządzanie"
                @click="proceedTo('ris-manage')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.workshopCare"
                icon="bandage"
                label="Opieka"
                disabled
                @click="proceedTo('rehabilitation-workshop')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.workshopDiagnostics"
                icon="timer-sand"
                disabled
                label="Diagnostyka"
                @click="proceedTo('rehabilitation-workshop')"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list
              label="Punkty pobrań"
              v-if="
                perms.collectionPoint ||
                perms.officeMidwife ||
                perms.gynecologistAssistant ||
                perms.histopathologyCollectionPoint ||
                perms.viralCollectionPoint
                "
            >
              <b-menu-item
                v-if="perms.collectionPoint"
                icon="rhombus-split"
                label="Punkt pobrań"
                @click="proceedTo('collection-point')"
              ></b-menu-item>
              <b-menu-item
                icon="needle"
                v-if="(perms.officeMidwife || perms.gynecologistAssistant) && !perms.midwifeCollectionPointHidden"
                label="Punkt pobrań położnej"
                @click="proceedTo('lab-cytology')"
              ></b-menu-item>
              <b-menu-item
                icon="needle"
                v-if="perms.histopathologyCollectionPoint"
                label="Punkt pobrań histpat."
                @click="proceedTo('lab-histpat')"
              ></b-menu-item>
              <!-- <b-menu-item
                icon="needle"
                v-if="perms.admin"
                label="Punkt pobrań położnej v2"
                @click="proceedTo('lab-cytology')"
              ></b-menu-item>-->
              <b-menu-item
                v-if="perms.viralCollectionPoint"
                label="Punkt pobrań mat. zakaźnego"
                icon="virus"
                @click="proceedTo('lab-covid')"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list
              v-if="
                perms.subadmin ||
                perms.officeMidwife ||
                perms.administrationPopulationManagement || 
                perms.administrationPatientCoordination
              "
              label="Opieka"
            >
              <b-tooltip
                label="Zarządzanie populacją"
                v-if="
                  perms.subadmin ||
                  perms.officeMidwife ||
                  perms.administrationPopulationManagement
                "
              >
                <b-menu-item
                  icon="account-alert"
                  label="Zarządzanie populacją"
                  @click="proceedTo('lab-cytology-high-risk-patients')"
                ></b-menu-item>
              </b-tooltip>
              <b-tooltip
                label="Panel koordynacji"
                v-if="perms.administrationPatientCoordination"
              >
                <b-menu-item
                  icon="account-alert"
                  label="Panel koordynacji"
                  @click="proceedTo('patient-management')"
                ></b-menu-item>
              </b-tooltip>
              <b-menu-item
                v-if="perms.subadmin"
                @click="proceedTo('case-conference')"
                icon="video-outline"
                label="Telekonsylia"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list
              v-if="
                perms.subadmin ||
                perms.officeMidwife ||
                perms.administrationPopulationManagement ||
                perms.projectOsteoporosis ||
                perms.projectTomography || 
                perms.project40Plus
              "
              label="Projekty czasowe"
            >
              <b-menu-item
                v-if="perms.admin || perms.subadmin"
                icon="diabetes"
                label="Diabetologia"
                @click="proceedTo('projects-diabetes-patients-list')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.project40Plus"
                icon="diabetes"
                label="Program 40 Plus"
                @click="proceedTo('projects-40-plus-list')"
              ></b-menu-item>

              <b-menu-item
                v-if="perms.projectOsteoporosis"
                icon="exclamation-thick"
                label="Osteoporoza"
                @click="proceedTo('projects-osteoporosis-patients-list')"
              ></b-menu-item>
              <b-menu-item
                v-if="perms.projectTomography"
                icon="radiology-box-outline"
                label="Niskodawkowa tomografia płuc"
                @click="proceedTo('projects-tomography-patients-list')"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
      </div>
      <div style="position: absolute; top: 10px; right: 10px; z-index: 11">
        <b-tooltip
          label="Zamknij menu"
          position="is-left"
          :delay="500"
          :animated="false"
          @click="open = false"
        >
          <b-button
            type="is-primary"
            icon-left="close"
            outlined
            @click="open = false"
            size="is-small"
          ></b-button>
        </b-tooltip>
      </div>
      <!-- <img src="logo.svg" style="width:96%;position:absolute;top:0;left:0; padding:4% 0 4% 4%;z-index:1;opacity:0.1;" /> -->
    </b-sidebar>

    <!-- <b-modal
      :active.sync="chatOpen"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        title="SZOK Talk"
        icon="chat"
        :paddingless="true"
        :marginless="true"
      >
        <template slot="addon">
          <b-button
            @click="chatOpen = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </template>
        <SzokTalkDashboard></SzokTalkDashboard>
      </ContentPanel>
    </b-modal> -->
  </div>
</template>

<script>
import CommonMixins from "../../mixins/commons";
import DomainsMixins from "../../mixins/domains";
import IdentityPanel from "../identity/IdentityPanel";
import { Action } from "../../store/config_actions";
import { Mutation } from "../../store/config_mutations";
import { RUN_MODE } from "../../store/config_apis";
import UserFeedback from "../tools/UserFeedback";
import CrmReportForm from "../crm/CrmReportForm";
import FacilitySelector from "../tools/FacilitySelector";
import FontSizeSelector from "./FontSizeSelectorButtons";
import NavPLC from "./NavPLC";
import NavP12 from "./NavP12";
//import SzokTalk from "../talk/TalkNavigationButton";
//import SzokTalkDashboard from "../talk-2/SzokTalkDashboard";
import TalkAlerts from "../talk/TalkAlerts";
import MySchedule from "@/components/schedule2/wrappers/MySchedule";
import P12 from "../p12/P12DocPassword";
import LinkConnector from "../e-signature/LinkConnector";
import moment from "moment";
import SimpleKeyboard from "@/components/tools/SimpleKeyboard";
// import ContentPanel from '@/components/tools/ContentPanel'
//import ScheduleDomainSelector from '@/components/navigation/ScheduleDomainSelector'

export default {
  name: "SiteNavigation",
  mixins: [CommonMixins, DomainsMixins],

  components: {
    IdentityPanel,
    UserFeedback,
    CrmReportForm,
    FacilitySelector,
    FontSizeSelector,
    NavPLC,
    //SzokTalk,
    MySchedule,
    TalkAlerts,
    P12,
    LinkConnector,
    SimpleKeyboard,
    //SzokTalkDashboard,
    NavP12,
    //  ContentPanel
  },

  data: function () {
    return {
      pretender: null,
      patient: null,
      open: false,
      scheduleOpen: false,
      //chatOpen: false,
      loggingOut: false,
      story: 3,
      domainSelected: -1,
      chatMessages: [],

      searchEmployee: "",
    };
  },

  mounted() {
    if (this.myData) {
      this.domainSelected = this.myData.domainId;
    }
  },

  watch: {
    myData(val) {
      if (val) {
        this.domainSelected = val.domainId;
      }
    },
  },

  computed: {
    runMode() {
      return RUN_MODE;
    },

    myDomains() {
      let doms = [];

      if (this.myData) {
        doms.push(this.myData.domainId);
      }

      if (this.myData.otherDomainAccess) {
        this.myData.otherDomainAccess.forEach((element) => {
          if (!doms.includes(element)) doms.push(element);
        });
      }

      return doms;
    },

    sessionEndsIn: function () {
      return this.$store.state.identity.session
        ? this.$store.state.identity.session.activity
        : "0";
    },

    isServiceTime() {
      let now = parseInt(moment().format("HH"));
      return now > 20 || now < 6;
    },

    isProduction() {
      let location = window.location.href;
      if (location.includes("szok.digitmed")) return 1;
      else if (location.includes("klon")) return 2;
      else return 0;
    },

    loginBlock() {
      return this.$store.state.uac.loginBlock;
    },

    perms() {
      return this.$store.state.employee.permits;
    },

    myData() {
      return this.$store.state.employee.me;
    },

    employees() {
      return this.$store.state.employee.all ?? [];
    },

    filteredEmployee() {
      if (this.employees) {
        return this.employees.filter((option) => {
          if (option.name) {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.searchEmployee.toLowerCase()) >= 0
            );
          } else return false;
        });
      } else return [];
    },

    currentRouteName() {
      return this.$route.meta.desc ?? "SZOK";
    },
    myRoles: function () {
      return this.$store.getters.getMyRoles;
    },
    pretenders: function () {
      return this.$store.state.employee.scheduledEmployees;
    },
    patients: function () {
      return this.$store.state.patients.patients;
    },
    email: function () {
      return this.$store.state.identity.session.email;
    },

    canSeePOZ() {
      if (this.myRoles) {
        if (this.myRoles.includes("Doctor") || this.myRoles.includes("Admin")) {
          return true;
        }
      }

      return false;
    },

    canSeeNurseOffice() {
      if (this.myRoles) {
        if (this.myRoles.includes("Nurse") || this.myRoles.includes("Admin")) {
          return true;
        }
      }

      return false;
    },

    canSeePharmacistOffice() {
      if (this.myRoles) {
        if (
          this.myRoles.includes("Pharmacist") ||
          this.myRoles.includes("Admin")
        ) {
          return true;
        }
      }

      return false;
    },

    canSeeMobi() {
      if (this.myRoles) {
        if (
          this.myRoles.includes("MobiManager") ||
          this.myRoles.includes("Admin")
        ) {
          return true;
        }
      }

      return false;
    },

    canSeeOrders() {
      if (this.myRoles) {
        if (
          this.myRoles.includes("OrdersOperator") ||
          this.myRoles.includes("Admin")
        ) {
          return true;
        }
      }

      return false;
    },

    canSeeWorkers() {
      if (this.myRoles) {
        if (
          this.myRoles.includes("Management") ||
          this.myRoles.includes("Admin")
        ) {
          return true;
        }
      }

      return false;
    },

    canSeeCallCenter() {
      if (this.myRoles) {
        if (
          this.myRoles.includes("CallCenterWorker") ||
          this.myRoles.includes("CallCenterManager") ||
          this.myRoles.includes("Admin")
        ) {
          return true;
        }
      }

      return false;
    },

    canSeeELearning() {
      if (this.myRoles) {
        if (this.myRoles.includes("Admin")) {
          return true;
        }
      }

      return false;
    },

    canSeePatientPortal() {
      if (this.myRoles) {
        if (this.myRoles.includes("Admin")) {
          return true;
        }
      }

      return false;
    },

    canSeeRegistration() {
      if (this.myRoles) {
        if (
          this.myRoles.includes("Nurse") ||
          this.myRoles.includes("Doctor") ||
          this.myRoles.includes("RagistrationWorker") ||
          this.myRoles.includes("Admin")
        ) {
          return true;
        }
      }

      return false;
    },

    routerLoading() {
      return this.$store.state.identity.routerLoading;
    },

    routerLabel() {
      return this.$store.state.identity.routerLabel;
    },

    session() {
      if (this.$store.state.identity.session) {
        return this.$store.state.identity.session;
      } else {
        return "";
      }
    },
  },

  methods: {
    changeMyDomain() {
      this.$buefy.dialog.confirm({
        message: `Czy na pewno chcesz zmienić obszar na <b>${this.mGetDomainDesc(
          this.domainSelected
        )}</b>? Bedzie to wymagać ponownego zalogowania się.`,
        cancelText: "Anuluj",
        confirmText: "Tak, zmień i wyloguj",
        type: "is-info",
        hasIcon: true,
        scroll: "keep",

        onConfirm: () => {
          this.loggingOut = true;
          this.$store
            .dispatch(Action.EMPLOYEE_CHANGE_MY_DOMAIN, this.domainSelected)
            .then(() => {
              this.loggingOut = true;
              this.$store
                .dispatch(Action.IDENTITY_LOGOUT, "DOMAIN_CHANGE")
                .then(() => {
                  this.loggingOut = false;
                  this.warningSnackbar(this.t("identity.userLoggedOut"));
                });
            })
            .catch((error) => {
              this.apiProblem(error);
            });
        },
        onCancel: () => {
          this.domainSelected = this.myData.domainId;
        },
      });
    },

    cloneToken() {
      if (this.session && this.session.token) {
        navigator.clipboard.writeText(this.session.token);
        this.snackbar("Remember, there is no spoon...");
      }
    },

    fullscreeen() {
      this.requestFullscreen(document.documentElement);
    },

    requestFullscreen(element) {
      if (element.requestFullScreen) {
        element.requestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      }
    },

    proceedTo(name) {
      this.open = false;
      this.$router.push({ name: name });
    },

    toggleSidebar() {
      if (this.perms.myAccount) {
        this.open = true;
      }
    },

    pretend(pretender) {
      if (pretender) {
        this.pretender = pretender;
        this.$store.commit(Mutation.WORKER_PRETEND, pretender);
        this.successSnackbar("Udajesz: " + pretender.name);
      } else {
        this.pretender = null;
        this.$store.commit(Mutation.WORKER_PRETEND, null);
        this.successSnackbar("Przestałeś udawać lekarza");
      }
    },

    patientPretend() {
      this.$store
        .dispatch(Action.PATIENT_GET_MY_DATA, this.patient)
        .then(() => {
          this.successSnackbar("Pobrano dane medyczne pacjenta");
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },
    getPatientDiagnoses() {
      this.$store
        .dispatch(Action.POZ_GET_DIAGNOSES, this.patient)
        .then(() => {
          this.successSnackbar("Pobrano informacje o rozpoznaniach pacjenta");
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },
    getPatientAppointments() {
      this.$store
        .dispatch(Action.POZ_GET_APPOINTMENTS, this.patient)
        .then(() => {
          this.successSnackbar("Pobrano informacje o wizytach pacjenta");
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },

    logout() {
      this.$buefy.dialog.confirm({
        message: "Czy na pewno chcesz się wylogować?",
        cancelText: "Anuluj",
        confirmText: "Tak, wyloguj",
        type: "is-info",
        hasIcon: true,
        scroll: "keep",

        onConfirm: () => {
          this.loggingOut = true;
          this.$store.dispatch(Action.IDENTITY_LOGOUT, "FROM_NAV").then(() => {
            // this.$router.push({ name: 'login' })
            this.loggingOut = false;
            this.warningSnackbar(this.t("identity.userLoggedOut"));
          });
        },
      });
    },

    warningSnackbar(message) {
      this.$buefy.snackbar.open({
        message: message,
        queue: false,
        type: "is-warning",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.background-img {
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.hoverable:hover {
  color: whitesmoke !important;
}

.router-warning {
  position: fixed;
  left: 3rem;
  bottom: 4rem;
  //transform: translate(0%, 0%);
  z-index: 10;
  //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.background-nav {
  background-image: url("~@/assets/bg3.jpg");
  background-position-x: 20%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.background {
  background-image: url("~@/assets/loader.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.meter {
  position: relative;
  box-sizing: content-box;
  height: calc(0.5rem - 1px);
  margin: -1px 1rem -0.5rem 1rem;
  border: 1px solid #83c8f3;

  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.meter > span {
  display: block;
  height: 100%;
  background-color: #398cbf;
  background-image: linear-gradient(center bottom, #398cbf, #398cbf);
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 100px 100px;
  animation: move 1s linear infinite;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.handpointer {
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
