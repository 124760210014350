<template>
  <div>
    <b-modal
      :active.sync="facilityCheckModal"
      :can-cancel="false"
      has-modal-card
      scroll="keep"
      :destroy-on-hide="false"
      @keydown.native.enter="facilityChoice"
      style="z-index: 99999"
      @shown="$refs.confirmFacilityChoice.$el.focus()"
    >
      <ContentPanel title="Wybór placówki rejestracyjnej" icon="home" :paddingless="true" :marginless="true">
        <div class="modal-card" style="min-width: 600px; min-height: 480px">
          <section class="modal-card-body w-100 pt-3 pl-3 pr-3">

            <b-message type="is-await" has-icon icon="alert">
              <p class="title is-5 mb-4">Wybór placówki rejestracyjnej</p>
              <p class="mb-3">
                Od
                <b>18 grudnia 2024</b> wszyscy pracownicy rejestracji muszą potwierdzić swoje obecne miejsce pracy.
              </p>
            </b-message>

            <b-field class="wide" label="Wybierz placówkę" label-position="on-border">
              <b-autocomplete
                v-model="facilityFilter"
                :open-on-focus="true"
                :data="filteredFacilities"
                field="name"
                @select="(option) => (selectedFacility = option ? option : null)"
                clearable
                expanded
                class="wide"
              >
                <template slot-scope="props">
                  <div>
                    <p class="has-text-weight-semibold">{{ props.option.name }}</p>
                    <p v-if="props.option.address">
                      {{ props.option.address.street }}
                      {{ props.option.address.buildingNumber }}
                      {{ props.option.address.apartmentNumber ? "/ " : "" }}
                      {{ props.option.address.apartmentNumber }}
                      <br />
                      {{ props.option.address.zipCode }}
                      {{ props.option.address.city }}
                    </p>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>

            <div class="card p-2 mb-3" v-if="selectedFacility">
              <table>
                <tr>
                  <td>
                      <p class="mb-2">
                        <b-icon icon="home"></b-icon>
                        <span class="has-text-weight-medium has-text-success ml-2">{{ selectedFacility.name }}</span>
                      </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <b-icon icon="map-marker"></b-icon>
                      <span class="is-italic ml-2">
                        <span v-if="selectedFacility.address">{{ selectedFacility.address.street }}
                          {{ selectedFacility.address.buildingNumber }}
                          {{ selectedFacility.address.apartmentNumber ? "/ " : "" }}
                          {{ selectedFacility.address.apartmentNumber }}
                          ,
                          {{ selectedFacility.address.zipCode }}
                          {{ selectedFacility.address.city }}
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot buttons is-right">
            <b-button ref="confirmFacilityChoice" @click="facilityChoice" :disabled="!selectedFacility" type="is-success"
              >Potwierdź wybór placówki</b-button
            >
          </footer>
        </div>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons"
import DateHelpers from "@/mixins/date_helpers"
import { Mutation } from "@/store/config_mutations"

export default {
  name: "EmployeeFacilityCheck",

  components: {},

  mixins: [CommonMixins, DateHelpers],

  props: {
    trigger: { type: Number, required: false },
  },

  data: function () {
    return {
      facilityCheckModal: false,

      facilityFilter: "",
      selectedFacility: null,
    }
  },

  watch: {
    trigger() {
      if (this.meParentFacilityObject) {
        this.facilityFilter = this.meParentFacilityObject.name
        this.selectedFacility = this.meParentFacilityObject
      }

      this.facilityCheckModal = true

      this.$nextTick(() => {
        this.$refs.confirmFacilityChoice.$el.focus()
      })
    },
  },

  computed: {
    registrationFacility() {
      return this.$store.state.identity.registrationFacility
    },

    facilities() {
      return this.$store.state.clinic.clinics ?? []
    },

    filteredFacilities() {
      if (this.facilities) {
        return this.facilities.filter((option) => {
          let result = false
          if (option.name) {
            result = option.name.toString().toLowerCase().indexOf(this.facilityFilter.toLowerCase()) >= 0
          }
          if (!result && option.address) {
            if (option.address.street) {
              result = option.address.street.toString().toLowerCase().indexOf(this.facilityFilter.toLowerCase()) >= 0
            }

            if (!result && option.address.city) {
              result = option.address.city.toString().toLowerCase().indexOf(this.facilityFilter.toLowerCase()) >= 0
            }
          }
          if (!result && option.name) {
            result = option.name.toString().toLowerCase().indexOf(this.facilityFilter.toLowerCase()) >= 0
          }

          return result
        })
      }

      return []
    },

    perms() {
      return this.$store.state.employee.permits
    },

    me() {
      return this.$store.state.employee.me
    },

    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility)
        return found
      } else return null
    },
  },

  methods: {
    facilityChoice() {
      if (this.selectedFacility) {
        this.$store.commit(Mutation.REGISTRATION_SET_REGISTRATION_FACILITY, this.selectedFacility)
        this.facilityCheckModal = false
      }
    },
  },
}
</script>
