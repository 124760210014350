import axios from 'axios'
import { Apis } from '../config_apis'
import { Action } from '../config_actions'

export default {

    state: {

    },

    getters: {},

    mutations: {

    },

    actions: {
        [Action.VERIFY_FILE_SET_VERIFIED](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.VERIFY_FILE_SET_VERIFIED.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all files to verify. Uses GET request without parameter */
        [Action.VERIFIED_FILE_UPLOAD_GET](context, domain) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.VERIFIED_FILE_UPLOAD_GET.replace('{domain}', domain),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}