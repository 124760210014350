import moment from 'moment'
import { Action } from '../store/config_actions'
import { Mutation } from '@/store/config_mutations'

const monthNames = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"]

export default {
  data: function () {
    return {
    }
  },

  computed: {
    mActions() { return this.$store.state.actions },
    mMutations() { return this.$store.state.mutations },
    mCookies() { return this.$store.state.cookies },

    mAppointmentTypes() { return this.$store.state.config.appointments },

    mFacilities() { return this.$store.state.clinic.clinics },
    mOffices() { return this.$store.state.offices.offices },
    mRooms() { return this.$store.state.offices.rooms },

    mAreaTypes() { return this.$store.state.config.areas },
    mOfficeTypes() { return this.$store.state.config.offices },
    mClinicTypes() { return this.$store.state.config.clinics },
    mEmployeeAll() { return this.$store.state.employee.all },

    /**
     * Czy user zalogowany
     */
    isUserAuthorized() { return this.$store.getters['isUserAuthorized'] },
  },

  methods: {

    mPrintBackendWithConfig(config) {
      // {
      //   "htmlContent": "string",
      //   "landscape": true,
      //   "pageSize": 0,
      //   "customWidth": 0,
      //   "customHeight": 0,
      //   "useCustomMargin": true,
      //   "customMarginTop": 0,
      //   "customMarginBottom": 0,
      //   "customMarginLeft": 0,
      //   "customMarginRight": 0,
      //   "optionalHeaderFromSecondPage": "string",
      //   "printHtmlBackgrounds": true,
      //   "createPdfFormsFromHtml": true
      // }
      if (config.htmlContent) {
        this.$store.commit(Mutation.CONFIG_SET_PRINT_LOADING, true);
        this.$store
          .dispatch(this.mActions.GOLD_TOOLS_PDF_FROM_HTML, config)
          .then((response) => {
            var blob = new Blob([response], { type: "application/pdf" })
            let url = URL.createObjectURL(blob)
            window.open(url, "_blank", "location=yes,scrollbars=yes")
            URL.revokeObjectURL(url)
            this.$store.commit(Mutation.CONFIG_SET_PRINT_LOADING, false);
          })
          .catch((error) => {
            this.apiProblem(error)
            this.$store.commit(Mutation.CONFIG_SET_PRINT_LOADING, false);
          });
      }
    },

    mPrintBackend(reference, customPageMode, customPageWidth, customPageHeight, landscape, useDirectContent) {

      var content = null

      if (useDirectContent) {
        content = reference
      }
      else {
        if (this.$refs[reference]) {
          content = this.$refs[reference].innerHTML
        }
      }

      if (content) {
        let test =
        {
          "htmlContent": content,
          "landscape": false,
          "pageSize": 0,
          "customWidth": 0,
          "customHeight": 0,
          "useCustomMargin": true,
          "customMarginTop": 0,
          "customMarginBottom": 0,
          "customMarginLeft": 0,
          "customMarginRight": 0,
          "optionalHeaderFromSecondPage": null,
          "printHtmlBackgrounds": false,
          "createPdfFormsFromHtml": false
        }

        if (landscape) {
          test.landscape = true
        }

        if (customPageMode) {
          test.pageSize = -1
        }

        if (customPageHeight) {
          test.customHeight = customPageHeight
        }

        if (customPageWidth) {
          test.customWidth = customPageWidth
        }

        this.$store
          .dispatch(this.mActions.GOLD_TOOLS_PDF_FROM_HTML, test)
          .then((response) => {
            var blob = new Blob([response], { type: "application/pdf" })
            let url = URL.createObjectURL(blob)
            window.open(url, "_blank", "location=yes,scrollbars=yes")
            URL.revokeObjectURL(url)
          })
          .catch((error) => {
            this.apiProblem(error)
          });
      }
    },


    mGetMonthName(value) {
      return value > 0 ? monthNames[value - 1] : 'Błąd';
    },

    mGetServiceIcon(item) {
      switch (item) {
        case 0: return 'home-city'
        case 1: return 'phone'
        case 2: return 'car'
        default: return 'question'
      }
    },

    mGetUmxPrime(code, bothCodes = false) {
      let val = ""
      switch (code) {

        case '030000445240001': val = '03-13-01-00445-01'; break;





        case '070001726250209': val = '07R-3-30074-01-01-2016'; break;
        case '070001726250603': val = '07R-3-30074-02-01-2018-2025'; break;
        case '070001726250604': val = '07R-3-30074-02-01-2021-2026'; break;
        case '070001726250605': val = '07R-3-30074-02-01-2024-2029'; break;
        case '070001726250606': val = '07R-3-30074-02-02-2019-2025'; break;
        case '070001726250607': val = '07R-3-30074-02-03-2021-2026'; break;
        case '070001726250608': val = '07R-3-30074-02-02-2021-2026'; break;
        case '070001726250613': val = '07R-3-30074-02-01-2011-2025'; break;
        case '070001726250621': val = '07R-3-30074-18-25-2024-2025'; break;




        case '070001726240217': val = '07R-3-30074-01-01-2016'; break;
        case '070001726240608': val = '07R-3-30074-02-02-2019-2025'; break;
        case '070001726240609': val = '07R-3-30074-02-02-2021-2026'; break;
        case '070001726240611': val = '07R-3-30074-02-01-2011-2025'; break;
        case '070001726240612': val = '07R-3-30074-18-08-2021'; break;
        case '070001726240635': val = '07R-3-30074-02-01-2024-2029'; break;
        case '070606721000000': val = '07R-1-01825-02-01-2011-2025'; break;
        case '070001726220607': val = '07R-3-30074-02-02-2021-2026'; break;
        case '070001726220608': val = '07R-3-30074-02-02-2019-2023'; break;
        case '070001726230218': val = '07R-3-30074-01-01-2016'; break;
        case '070001726230606': val = '07R-3-30074-02-02-2019-2023'; break;
        case '070001726230613': val = '07R-3-30074-02-02-2021-2026'; break;
        case '070001726240639': val = '07R-3-30074-18-25-2024-2025'; break;

        case '030003622240008': val = '03-16-01-03622-01'; break;
        case '030003622240014': val = '03-24-18-03622-01'; break;

        case '070606721240607': val = '07R-1-01825-02-01-2011-2025'; break;
        case '070606721240605': val = '07R-1-01825-01-01-2016'; break;
        case '070606721230605': val = '07R-1-01825-01-01-2016'; break;
        case '070606721230603': val = '07R-1-01825-18-08-2021'; break;
        case '070606721230604': val = '07R-1-01825-04-01-2019-2023'; break;
        case '070606721230602': val = '07R-1-01825-07-01-2017-2023'; break;
        case '070606721230601': val = '07R-1-01825-02-01-2011-2023'; break;
        case '070606721220608': val = '07R-1-01825-01-01-2016'; break;
        case '070606721220602': val = '07R-1-01825-02-01-2011-2022'; break;

        case '070603762240608': val = '07R-1-01362-01-01-2016'; break;
        case '070603762240604': val = '07R-1-01362-18-08-2021'; break;
        case '070603762230607': val = '07R-1-01362-10-01-2022-2023'; break;
        case '070603762230606': val = '07R-1-01362-10-01-2022-2027'; break;
        case '070603762230605': val = '07R-1-01362-01-01-2016'; break;
        case '070603762230604': val = '07R-1-01362-18-08-2021'; break;
        case '070603762230603': val = '07R-1-01362-02-02-2022-2026'; break;
        case '070603762230602': val = '07R-1-01362-02-01-2012-2023'; break;
        case '070603762230601': val = '07R-1-01362-05-01-2017-2023'; break;
        case '070603762220614': val = '07R-1-01362-02-02-2022-2026'; break;
        case '070603762220603': val = '07R-1-01362-05-01-2017-2022'; break;
        case '070603762220602': val = '07R-1-01362-01-01-2016'; break;
        case '070603762220601': val = '07R-1-01362-02-01-2012-2022'; break;
        case '070001726220606': val = '07R-3-30074-02-03-2021-2026'; break;

        case null:
          val = ''
          bothCodes = false
          break

        default: val = `${code}`; break;
      }

      if (val == code) bothCodes = false

      return bothCodes ? `${val} (${code})` : val
    },

    mGetAppType(id) {
      var app = this.mAppointmentTypes.find(a => a.id === id)
      return app ? app.name : `Brak informacji [${id}]`
    },

    mGetAppSubtype(id, subId) {

      if (subId > 0) {
        var app = this.mAppointmentTypes.find(a => a.id === id)

        if (app && app.subcategories) {
          var sub = app.subcategories.find(a => a.id === subId)
          return sub ? sub.name : `Brak informacji o podtypie [${subId}]`
        }
        else {
          return `Brak informacji [${id}/${subId}]`
        }
      }
      else {
        return null
      }

    },
    mGetAppIcon(id) {
      var app = this.mAppointmentTypes.find(a => a.id === id)
      return app ? app.icon : `help-circle-outline`
    },
    mGetOfficeType(id) {
      var app = this.mOfficeTypes.find(a => a.id === id)
      return app ? app.name : `Nieokreślony typ gabinety [${id}]`
    },
    mGetClinicType(id) {
      var app = this.mClinicTypes.find(a => a.id === id)
      return app ? app.name : `Nieokreślony typ poradni [${id}]`
    },
    mGetAreaType(id) {
      var app = this.mAreaTypes.find(a => a.id === id)
      return app ? app.name : `Brak informacji [${id}]`
    },
    mGetAreaIcon(id) {
      var app = this.mAreaTypes.find(a => a.id === id)
      return app ? app.icon : `help-circle-outline`
    },
    mGetRoom(id) {
      var app = this.mRooms.find(a => a.id === id)
      return app ? app.name : `Nieokreślony pokój`
    },
    mGetOffice(id) {
      var app = this.mOffices.find(a => a.id === id)
      return app ? app.name : `Nieokreślony gabinet`
    },
    mGetFacilityFromRoom(id) {
      let room = this.mRooms.find(a => a.id === id)
      let app = null

      if (room) {
        app = this.mFacilities.find(a => a.id === room.facilityId)
      }

      return app ? app.name : `Nieokreślona placówka`
    },

    mGetEmployee(id) {
      var app = this.mEmployeeAll.find(a => a.id === id)
      return app ? app.name : `Brak informacji [${id}]`
    },


    mCopy(mainObj, targetObj) {
      let key;

      for (key in mainObj) {
        targetObj[key] = mainObj[key]; // copies each property to the objCopy object
      }
    },

    mPrice(value) {
      if (isNaN(value)) {
        return '-'
      }
      else {
        return value.toLocaleString('pl-PL', {
          useGrouping: 'true',
          minimumFractionDigits: "2",
          maximumFractionDigits: "2"
        })
      }
    },

    mNowRaw() {
      return moment().format('YYYYMMDDTHHmmssSSS')
    },

    mTimestamp() {
      return moment().format('YYMMDDHHmmssSSS')
    },


    mTimestamp2() {
      return moment().format('YYMMDDHHm')
    },

    mDateTimeFull(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss.SSS')
    },

    mDateTimeSeconds(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },

    mNow() {
      return moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    },

    mDateTimeRaw(item) {
      return moment(item).format('YYYY-MM-DDTHH:mm:ss.SSS')
    },

    mDateTimeRawZone(item) {
      return moment(item).format('YYYY-MM-DDTHH:mm:ss.SSS')
    },

    mNowDate() {
      return moment().format('YYYY-MM-DD')
    },

    mDateTime(dateString) {
      return moment(dateString).format('YYYY-MM-DD HH:mm')
    },

    mDateTimeBr(dateString) {
      return moment(dateString).format('YYYY-MM-DD<br />HH:mm')
    },

    mDate(dateString) {
      return moment(dateString).format('YYYY-MM-DD')
    },

    mDateNoYear(dateString) {
      return moment(dateString).format('DD.MM')
    },

    mTime(dateString) {
      return moment(dateString).format('HH:mm')
    },

    mTimeSec(dateString) {
      return moment(dateString).format('HH:mm:ss')
    },

    mDays(dateString) {
      return moment().diff(moment(dateString), 'days')
    },

    mFullDays(dateString) {
      return moment().startOf('day').diff(moment(dateString).startOf('day'), 'days')
    },

    todo() {
      this.snackbar('W budowie :)')
    },

    mHasProp(prop) {
      if (prop) return true
      else return false
    },

    mTimeAfter(startDate, date) {
      return moment(date).isAfter(startDate, 'day');
    },

    handleLoginBlock(minutesToUnlock) {

      let minutes = Math.floor(minutesToUnlock)
      let secondsRest = minutesToUnlock - minutes
      let seconds = Math.floor(60 * secondsRest)

      let unitM = 'minutę'
      let restM = minutes % 10

      let unitS = 'sekundę'
      let restS = seconds % 10

      if (minutes != 1) {
        if (restM > 0 && restM < 5) unitM = 'minuty'
        else unitM = 'minut'
      }

      if (seconds != 1) {
        if (seconds == 0) {
          seconds = 1
          unitS = "sekundę"
        }
        else if (seconds > 0 && seconds < 5) unitS = "sekundy"
        else if (seconds > 10 && seconds < 22) unitS = "sekund"
        else if (restS > 1 && restS < 5) unitS = 'sekundy'
        else unitS = 'sekund'
      }

      if (seconds == 0) {
        unitS = ''
        seconds = ''
      }

      if (minutes == 0) {
        unitM = ''
        minutes = ''
      }

      if (unitS.length > 0) {
        unitM = unitM + ' i '
        seconds = seconds + ' '
      }

      this.$buefy.dialog.alert({
        //message: '<b>Wybrany termin został w międzyczasie zarezerwowany.</b> Możliwe jest zapisanie tymczasowej rezerwacji, a następnie przełożenie jej na inny, wybrany termin.',
        message: `Ze względu na zbyt dużą liczbę niepoprawnych prób ponowne logowanie będzie możliwe za<br /><b>${minutes} ${unitM}${seconds}${unitS}</b>.`,
        type: 'is-danger',
        scroll: 'keep',
        hasIcon: true
      })
    },

    apiProblem(error) {
      if (error.response) {
        if (error.response.data.internalCode) {
          switch (error.response.data.internalCode) {
            case 2011:
              if (error.response.data.extra) {
                this.handleLoginBlock(error.response.data.extra)
              }
              else {
                this.apiWarning(this.t(`apiProblems.${error.response.data.internalCode}`) + `<br /><span class="is-size-7 is-pulled-right">Kod błędu: ${error.response.data.internalCode}</span>`)
              }
              break
            case 6006:
              if (error.response.data.extra && error.response.data.extra.includes("AppointmentsOverlap")) {
                this.apiWarning("Kolizja z inną wizytą. Wymagane ponowne pobranie terminarza.")

                this.$buefy.dialog.alert({
                  //message: '<b>Wybrany termin został w międzyczasie zarezerwowany.</b> Możliwe jest zapisanie tymczasowej rezerwacji, a następnie przełożenie jej na inny, wybrany termin.',
                  message: '<b>Wybrany termin został w międzyczasie zarezerwowany.</b><br /> Proszę zmienić godzinę, zapisać wizytę jako "bez godziny" lub odświeżyć terminarz, aby pobrać aktualne dane.',
                  type: 'is-warning',
                  scroll: 'keep',
                  hasIcon: true
                })
              }
              else {
                this.apiWarning(this.t(`apiProblems.${error.response.data.internalCode}`) + `<br /><span class="is-size-7">Kod błędu: ${error.response.data.internalCode}</span>`)
              }
              break
            case 6007:
              this.apiWarning(this.t(`apiProblems.${error.response.data.internalCode}`) + `<br /><span class="is-size-7">Kod błędu: ${error.response.data.internalCode}</span>`)
              break
            case 6508:
              this.apiWarning(this.t(`apiProblems.${error.response.data.internalCode}`) + `<br /><span class="is-size-7">Kod błędu: ${error.response.data.internalCode}</span>`)

              this.$buefy.dialog.alert({
                //message: '<b>Wybrany termin został w międzyczasie zarezerwowany.</b> Możliwe jest zapisanie tymczasowej rezerwacji, a następnie przełożenie jej na inny, wybrany termin.',
                message: error.response.data.extra,
                type: 'is-danger',
                title:'E-Rejestracja',
                scroll: 'keep',
                hasIcon: true
              })

              break
            default:
              this.apiWarning(this.t(`apiProblems.${error.response.data.internalCode}`) + `<br /><span class="is-size-7">Kod błędu: ${error.response.data.internalCode}</span>`)
              break
          }
        }
        else {
          if (typeof error.response.data === 'string' || error.response.data instanceof String) {
            if (error.response.data.includes('wrong id - worker account not found')) {
              // ignore this case
            }
            else {
              if (error.response.data && error.response.data.length > 0) {
                this.apiWarning(error.response.data)
              }
              else {
                this.apiWarning('Serwer niedostępny (-6)')
              }
            }
          }
          else
            this.dangerToast(this.t('apiProblems.0'))
        }
      }
      else {
        if (error === '401') {
          this.apiWarning('Brak autoryzacji')
        }
        else if (error === '403') {
          this.apiWarning('Akcja zakazana')
        }
        else if (error === '502') {
          this.apiWarning('System jest obecnie wyłączony z powodu prac administracyjnych.')
        }
        else {
          if (error.toString() == "Error: Network Error") {
            this.apiWarning(`Serwer nie odpowiada.`)
          }
          else {
            this.apiWarning(`<b>Błąd:</b> ${error.toString()}`)
          }
        }
      }

      if (error && this.isUserAuthorized) {
        try {
          this.sendError(error)
        }
        catch {
          // do nothing
        }
      }
    },

    sendError(error) {

      // let localCookiesVals = this.$cookies.get("errors")
      // let localCookies = []

      // if (localCookiesVals) {
      //   // cool
      //   localCookies = JSON.parse(localCookiesVals)
      // }

      // let cookieName = 'e' + this.mTimestamp()
      // this.$cookies.set(cookieName, JSON.stringify(error))
      // localCookies.splice(0, 0, cookieName)
      // this.$cookies.set('errors', JSON.stringify(localCookies))

      let subject = {
        "code": 0,
        "status": 0,
        "url": null,
        "data": null,
        "error": null,
        "description": null,
        "stack": null,
        "method": null,
      }

      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.internalCode) {
              subject.code = error.response.data.internalCode
            }

            if (error.response.data.title) {
              subject.error = JSON.stringify(error.response.data.title)
            }

            subject.description = JSON.stringify(error.response.data)
          }

          if (error.response.status) {
            subject.status = error.response.status
          }

          if (error.response.config) {

            if (error.response.config.url) {
              subject.url = error.response.config.url
            }

            if (error.response.config.method) {
              subject.method = error.response.config.method
            }

            if (error.response.config.data) {
              subject.data = error.response.config.data
            }
          }
        }
        else {
          subject.description = JSON.stringify(error)

          if (error.message) {
            subject.error = JSON.stringify(error.message)
          }

          if (error.stack) {
            subject.stack = JSON.stringify(error.stack)
          }

          if (error.config) {

            if (error.config.url) {
              subject.url = error.config.url
            }

            if (error.config.method) {
              subject.method = error.config.method
            }

            if (error.config.data) {
              subject.data = error.config.data
            }
          }
        }
      }




      if (error && error.response && error.response.data) {
        if (error.response.data.internalCode && error.response.data.internalCode === 6007) {
          // ignore this case
          subject = null
        }

        if (error.response.data.internalCode && error.response.data.internalCode === 4003) {
          // ignore this case
          subject = null
        }
      }

      if (typeof error.response.data === 'string' || error.response.data instanceof String) {
        if (error.response.data.includes('wrong id - worker account not found')) {
          // ignore this case
          subject = null
        }
      }

      if (error && error.description && error.description.includes('wrong id - worker account not found')) {
        // ignore this case
        subject = null
      }

      if (subject) {
        if (subject.description) {
          if (subject.description.config) {
            if (subject.description.config.headers) {
              subject.description.config.headers = "hidden"
            }
          }
        }

        if (subject.url.includes('getEduProgresses')) {
          subject = null
        }
      }

      if (subject) {


        if (subject.description) {
          if (subject.description.includes('Bearer')) {
            subject.description = subject.description.substring(0, subject.description.indexOf("Bearer")) + " CENSORED :)"
          }
        }


        this.$store.dispatch(Action.STATS_API_ERROR_CREATE, subject)
          .then(() => {
          })
          .catch(() => {

            let subjectFlat = {
              "code": -1,
              "status": 0,
              "url": null,
              "data": null,
              "error": JSON.stringify(subject),
              "description": null,
              "stack": null,
              "method": null,
            }

            this.$store
              .dispatch(Action.STATS_API_ERROR_CREATE, subjectFlat)
              .then(() => {
              })
              .catch(() => {
                this.$store.dispatch(Action.STATS_API_ERROR_CREATE, subject)
                  .then(() => {
                  })
                  .catch(() => {

                    let subjectFlat = {
                      "code": -6,
                      "status": 0,
                      "url": null,
                      "data": null,
                      "error": JSON.stringify(error),
                      "description": null,
                      "stack": null,
                      "method": null,
                    }

                    this.$store.dispatch(Action.STATS_API_ERROR_CREATE, subjectFlat)
                      .then(() => {
                      })
                      .catch(() => {
                        this.successSnackbar("Nie można wysłać zgłoszenia błędu API");
                      });
                  });
              });
          });
      }
      else {
        this.$store.dispatch(Action.STATS_API_ERROR_CREATE, subject)
          .then(() => {
          })
          .catch(() => {

            let subjectFlat = {
              "code": -7,
              "status": 0,
              "url": null,
              "data": null,
              "error": JSON.stringify(error),
              "description": null,
              "stack": null,
              "method": null,
            }

            this.$store.dispatch(Action.STATS_API_ERROR_CREATE, subjectFlat)
              .then(() => {
              })
              .catch(() => {
                this.successSnackbar("Nie można wysłać zgłoszenia błędu API");
              });
          });
      }
    },

    mGetDayName(item) {
      let day = moment(item).format('dddd')

      switch (day) {
        case 'Monday': return 'Poniedziałek'
        case 'Tuesday': return 'Wtorek'
        case 'Wednesday': return 'Środa'
        case 'Thursday': return 'Czwartek'
        case 'Friday': return 'Piątek'
        case 'Saturday': return 'Sobota'
        case 'Sunday': return 'Niedziela'
        default: return item
      }
    },

    successToast(message) {
      this.$buefy.toast.open({
        message: message.toString(),
        type: 'is-success',
        queue: false,
        position: 'is-top'
      })
    },

    apiWarning(message) {
      this.$buefy.snackbar.open({
        message: message.toString(),
        type: 'is-danger',
        queue: false,
        position: 'is-bottom-right'
      })
    },

    dangerToast(message) {
      this.$buefy.toast.open({
        message: message.toString(),
        type: 'is-danger',
        queue: false,
        position: 'is-top',
        duration: 4000,
      })
    },

    toast(message) {
      this.$buefy.toast.open({
        message: message.toString(),
        type: 'is-primary',
        queue: false,
        position: 'is-bottom',
        duration: 5000
      })
    },

    warningToast(message) {
      this.$buefy.toast.open({
        message: message.toString(),
        type: 'is-warning',
        queue: false,
        position: 'is-bottom',
        duration: 5000
      })
    },


    secondaryToast(message) {
      this.$buefy.toast.open({
        message: message.toString(),
        type: 'is-secondary',
        queue: false,
        position: 'is-bottom',
        duration: 5000
      })
    },

    messageToast(message) {
      this.$buefy.toast.open({
        message: message.toString(),
        type: 'is-primary',
        queue: false,
        position: 'is-top',
        duration: 5000
      })
    },

    // Show bad snackbar.
    snackbar(msg) {
      //this.toast(msg)
      this.$buefy.snackbar.open({ message: msg.toString(), queue: false, type: 'is-info' })
    },

    // Show bad snackbar.
    dangerSnackbar(msg) {
      //this.dangerToast(msg)
      this.$buefy.snackbar.open({ message: msg.toString(), queue: false, type: 'is-danger' })
    },

    // Show good snackbar.
    successSnackbar(msg) {
      //this.successToast(msg)
      this.$buefy.snackbar.open({ message: msg.toString(), queue: false, type: 'is-success' })
    },

    infoSnackbar(msg) {
      this.$buefy.dialog.alert({
        type: 'is-danger',
        message: msg.toString()
      })
    },

    areArraysEqual(arrayOne, arrayTwo) {
      return JSON.stringify(arrayOne) === JSON.stringify(arrayTwo)
    },

    arrayComparerId(arrayOne, arrayIds) {
      if (arrayOne.length != arrayIds.length) return false
      if (arrayOne.every(item => arrayIds.includes(item.id))) return true
      else return false
    },

    arrayComparerGuid(arrayOne, arrayIds) {
      if (arrayOne.length != arrayIds.length) return false
      if (arrayOne.every(item => arrayIds.includes(item.guid))) return true
      else return false
    },

    arrayComparerRequestOrderId(arrayOne, arrayIds) {
      if (arrayOne.length != arrayIds.length) return false
      let arrayDeserialize = []
      arrayOne.forEach(element => {
        if (element) arrayDeserialize.push(JSON.parse(element))
      });
      if (arrayDeserialize.every(item => arrayIds.includes(item.requestOrderId))) return true
      else return false
    },

    arrayComparer(arrayOne, arrayTwo) {
      if (arrayOne.length != arrayTwo.length) return false
      if (arrayOne.every(item => arrayTwo.includes(item))) return true
      else return false
    },

    mGenerateUniqueId(domain, scheduleId, workerId, patientId) {
      return `${domain}_${scheduleId}_${workerId}_${patientId}_${this.mTimestamp()}`
    },

    mPrintReferralHtml(frameName, html) {
      if (html) {
        let newWin = window.frames[frameName]

        newWin.document.open()
        newWin.document.write(html)
        newWin.document.close()

        newWin.onload = () => {
          newWin.focus()
          newWin.print()
        }
      }
    },

    mPrintPrescriptionHtml(frameName, html) {
      if (html) {
        let newWin = window.frames[frameName]

        newWin.document.open()
        newWin.document.write(html)
        newWin.document.close()

        newWin.onload = () => {
          newWin.focus()
          newWin.print()
        }
      }
    },

    mDownloadInstruction(id) {

      this.loading = true
      this.$store
        .dispatch(Action.EDU_DOWNLOAD_INSTRUCTION, id)
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.apiProblem(error);
        });

    },

    mRandomUppercase(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },

    mPatientFileCheckSize(fileSize) {
      if (fileSize != null && fileSize != undefined) {
        if (fileSize / 1024 <= 25600) {
          return true
        }
      }

      this.$buefy.snackbar.open({
        message: 'Rozmiar pliku nie może być większy niż 25MB',
        type: 'is-warning',
        position: 'is-top',
      })
      return false
    }
  }
}
