<template>
  <div>
    <div
      class="has-text-light identity has-text-right hand"
      v-if="myData"
      @click="proceedTo('my-account')"
    >
      <b-tooltip
        label="Konto tymczasowo nieaktywne"
        type="is-danger"
        :delay="400"
        position="is-bottom"
        :animated="false"
        v-if="myData.status == 2"
      >
        <p class="has-text-weight-semibold has-text-danger">{{ myId }}</p>
      </b-tooltip>
      <b-tooltip
        label="Uwaga! Konto z poziomem Administrator"
        type="is-dark"
        :delay="400"
        position="is-bottom"
        :animated="false"
        v-else-if="myData.roles && myData.roles.includes(999)"
      >
        <p class="has-text-weight-semibold has-text-warning">[Admin] {{ myId }}</p>
      </b-tooltip>
      <p
        v-else
        class="has-text-weight-semibold has-text-warning"
      >{{ myId }}</p>
      <p class="is-size-7 has-text-primelight">{{ mGetDomainDesc(clinic.clinicId) }}</p>
    </div>
  </div>
</template>

<script>
// import { Action } from '../../store/config_actions'
// import { Cookies } from '../../store/config_cookies';
// import { Mutation } from '../../store/config_mutations';
import CommonMixins from "../../mixins/commons";
import DomainMixins from "../../mixins/domains";

//import moment from 'moment'

export default {
  name: "IdentityPanel",
  mixins: [CommonMixins, DomainMixins],

  mounted: function () {
    this.$cookies.set(this.mCookies.SZOK_ACTIVITY, this.mDateTimeRawZone());
    this.getInitialData();
  },

  data: function () {
    return {
      subject: "global",
      dataLoaded: 1,
    };
  },

  computed: {
    getProgressType() {
      var type = "";

      switch (this.dataLoaded) {
        case 11:
          type = "is-success";
          break;
      }

      return type;
    },

    loadingVisible() {
      return this.dataLoaded < 11;
    },

    sessionEndsIn: function () {
      return this.$store.state.identity.session
        ? this.$store.state.identity.session.activity
        : "0";
    },
    cookieEndsIn: function () {
      return this.$store.state.identity.cookieEndsIn;
    },
    email: function () {
      return this.$store.state.identity.session.email;
    },
    amIAdmin: function () {
      return this.$store.getters.isAdmin;
    },

    myData() {
      return this.$store.state.employee.me;
    },

    pretender() {
      return this.$store.state.workers.pretender;
    },

    clinic() {
      return this.$store.state.clinic.activeClinic;
    },

    tpk() {
      return this.$store.state.tpk;
    },

    myId() {
      if (this.pretender) {
        return this.pretender.name;
      }

      if (this.myData) {
        return `${this.myData.firstName} ${this.myData.lastName}`;
      } else {
        return this.email;
      }
    },
  },

  watch: {
    clinic(val) {
      if (val != null && val != undefined) {
        // this.getConfig()
      }
    },

    dataLoaded(val) {
      this.$store.commit("INITIAL_LOADING", val);
    },
  },

  methods: {
    getPrefix() {},

    proceedTo(name) {
      this.$router.push({ name: name });
    },

    clinicChanged(item) {
      var active = {
        clinicId: item,
        clinicName: item.toString(),
      };

      this.$store.commit(this.mMutations.CLINICS_SET_ACTIVE, active);
      //this.snackbar("Placówka zmieniona na " + item)
    },

    getMyPositions(item) {
      this.$store
        .dispatch(this.mActions.EMPLOYEE_POSITION_GET_ALL_BY_ME, item)
        .then(() => {
          //my postions setting
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },

    getInitialData() {
      if (this.myData) {
        this.dataLoaded++;
        this.clinicChanged(this.myData.domainId);
        this.getMyPositions(this.myData.id);
      } else {
        this.$store
          .dispatch(this.mActions.EMPLOYEE_GET_ME)
          .then((response) => {
            this.clinicChanged(response.domainId);
            this.getMyPositions(response.id);
            this.dataLoaded++;
          })
          .catch((error) => {
            this.dataLoaded++;
            this.apiProblem(error);
          });
      }

      this.$store
        .dispatch(this.mActions.FINANCE_ORGANIZATIONALUNIT_GET_CLUSTERS)
        .then(() => {
          this.dataLoaded++;
          //this.successSnackbar('Pobrano dane pracowników');
        })
        .catch((error) => {
          this.dataLoaded++;
          this.apiProblem(error);
        });

      this.$store
        .dispatch(this.mActions.MY_SETTINGS)
        .then(() => {
          // my settings downloaded
        })
        .catch((error) => {
          this.apiProblem(error);
        });

      this.$store
        .dispatch(this.mActions.FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL)
        .then(() => {
          this.dataLoaded++;
        })
        .catch((error) => {
          this.dataLoaded++;
          this.apiProblem(error);
        });

      this.$store
        .dispatch(this.mActions.EMPLOYEE_GET_ALL)
        .then(() => {
          this.dataLoaded++;
          this.$store
            .dispatch(this.mActions.OFFICE_GET_ALL)
            .then(() => {
              this.dataLoaded++;
            })
            .catch((error) => {
              this.dataLoaded++;
              this.apiProblem(error);
            });
          //this.successSnackbar('Pobrano dane pracowników');
        })
        .catch((error) => {
          this.apiProblem(error);
        });

      this.$store
        .dispatch(this.mActions.S3_CONFIG_GET, this.subject)
        .then(() => {
          this.dataLoaded++;
        })
        .catch((error) => {
          this.dataLoaded++;
          this.apiProblem(error);
        });

      this.$store
        .dispatch(this.mActions.CONFIG_GET_ALL_DICTIONARIES)
        .then(() => {
          this.dataLoaded++;
        })
        .catch((error) => {
          this.dataLoaded++;
          this.apiProblem(error);
        });

      /**
       * Downloads all privilege types.
       */
      this.$store
        .dispatch(this.mActions.PRIVILEGE_TYPE_GET_ALL)
        .then(() => {})
        .catch((error) => {
          this.apiProblem(error);
        });

      this.$store
        .dispatch(this.mActions.FINANCE_MEDICAL_SERVICES_GET_ALL)
        .then(() => {
          this.dataLoaded++;
        })
        .catch((error) => {
          this.dataLoaded++;
          this.apiProblem(error);
        });

      this.$store
        .dispatch(this.mActions.TALK_CREATE_CHAT_CONNECTION)
        .then(() => {})
        .catch((error) => {
          this.apiProblem(error);
        });

      // this.$store
      //   .dispatch(this.mActions.STATLOGS_CREATE_CHAT_CONNECTION)
      //   .then(() => {})
      //   .catch((error) => {
      //     this.apiProblem(error);
      //   });

      //if (!this.tpk) {

      // this.$store
      //   .dispatch(this.mActions.CLINICS_GET_ALL)
      //   .then(() => {
      //   }).catch(error => {
      //     this.apiProblem(error);
      //   })

      let includedPositions = [10, 11, 12, 20, 21, 40, 41, 50, 51];

      this.$store
        .dispatch(
          this.mActions.EMPLOYEE_GET_BY_MANY_POSITIONS,
          includedPositions
        )
        .then(() => {
          this.dataLoaded++;
        })
        .catch((error) => {
          this.dataLoaded++;
          this.apiProblem(error);
        });

      this.$store
        .dispatch(this.mActions.NFZ_SERVICE_GET_ALL)
        .then(() => {
          this.dataLoaded++;
        })
        .catch((error) => {
          this.dataLoaded++;
          this.apiProblem(error);
        });
      // }
    },

    warningSnackbar(message) {
      this.$buefy.snackbar.open({
        message: message,
        queue: false,
        type: "is-warning",
      });
    },

    getConfig() {
      this.$store
        .dispatch(this.mActions.S3_CONFIG_GET, this.subject)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#languageBarContainer {
  margin-left: 0.5rem;
}
.identity {
  line-height: 1rem !important;
}

.identity:hover {
  color: #6fc3f7 !important;
}

.inline-span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
</style>
