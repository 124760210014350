// This file contains the router configuration and route guards.
// MC. Updated: 2025-01-29.

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import cookies from 'vue-cookies'
import { Cookies } from '../store/config_cookies'
import { Mutation } from '../store/config_mutations'
import routes from './config_routes_3'

Vue.use(VueRouter)
const router = new VueRouter({ routes })

// Route guard!
router.beforeEach((to, from, next) => {

  // Check if route has BLOCK NAVIGATION flag 
  if (from && from.meta && from.meta.blockNavigation && store.state.identity.routerBlock) {
    window.alert("Uwaga! Proszę zakończyć wizytę lub użyć odpowiedniego przycisku do zawieszenia lub przerwania wizyty.")
  }
  else {

  // Set BLOCK NAVIGATION flag to false
  store.commit("ROUTER_SET_BLOCK", true)

  // Set ROUTER SWITCH flag to true (this is for loading animation)
  store.commit("ROUTER_SWITCH", true)

  // Set ROUTER LABEL to route description
  store.commit("ROUTER_LABEL", to.meta.desc ?? "SZOK")

  // Check if user is authorized
  let isAuth = store.getters.isUserAuthorized

  // Check if user is admin
  let isAdmin = store.getters.isAdmin

  // Routes allowed for anonymous users
  let allowedAnonymous = ['login', 'reset-password', 'reset-password-request']

  // Proceed if route is allowed for anonymous users
  if (allowedAnonymous.includes(to.name)) {
    next()
  }
  else {

    // Access to view flag
    let allowAccess = false

    // Pending logout flag
    let pendingLogout = false

    // If user is authorized
    if (isAuth) {

      // Check if user has required permits to view the route
      if (store.state && store.state.employee && store.state.employee.me && to.meta.permits) {
        to.meta.permits.forEach(permit => {
          if (store.state.employee.permits[permit]) {
            allowAccess = true
          }
        });
      }

      // Allow access if route has no permits
      if (to.meta.permits && to.meta.permits.length == 0) {
        allowAccess = true
      }

      // Block access to admin routes if user is not admin
      if (to.name.includes('admin')) {
        if (isAdmin) {
          allowAccess = true
        }
        else {
          next({ name: 'home' })
        }
      }
    }

    // If user is not authorized
    else {

      // Get user from cookies
      let cookieUser = cookies.get(Cookies.SZOK_USER_AUTH);

      // If user is found in cookies
      if (cookieUser) {

        // Open session with user from cookies
        store.commit(Mutation.IDENTITY_OPEN_SESSION, cookieUser)
        allowAccess = true
      }

      // If user is not found in cookies
      else {

        // Redirect to login page
        pendingLogout = true
        next({ name: 'login' })
      }
    }

    // If access is granted
    if (allowAccess) {
      // access to view granted
      next()
    }

    // If access is denied
    else {
      if (!pendingLogout) {

        // Show window alert
        window.alert('Brak wymaganych uprawnień do modułu: ' + to.meta.desc)
      }

      // Set ROUTER SWITCH flag to false (this is for loading animation
      store.commit("ROUTER_SWITCH", false)
    }
  }
  }
})

// Route guard aftermath!
router.afterEach((to) => {

  // Set ROUTER SWITCH flag to false (this is for loading animation)
  store.commit("ROUTER_SWITCH", false)

  // Routes allowed for anonymous users
  let allowedAnonymous = ['login', 'reset-password', 'reset-password-request']

  // Check if destination route is not allowed for anonymous users
  if (to.name != 'home' && !allowedAnonymous.includes(to.name)) {

    // Delayed check if user has required permits to view the route.
    // This allows to fully load user data before checking permits.
    setTimeout(() => {

      // Access flag
      let allowAccess = false

      // Check if user has required permits to view the route
      if (store.state && store.state.employee && store.state.employee.me && to.meta.permits) {
        to.meta.permits.forEach(permit => {
          if (store.state.employee.permits[permit]) {
            allowAccess = true
          }
        });
      }

      // Allow access if route has no permits
      if (to.meta.permits && to.meta.permits.length == 0) {
        allowAccess = true
      }

      // Block access to routes if user is not authorized
      if (!allowAccess) {
        window.alert('Brak wymaganych uprawnień do modułu: ' + to.meta.desc)
        router.push({ name: 'home' })
      }

    }, 1000);
  }
})

export default router
