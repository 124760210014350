<template>
  <div>
    <!-- <pre>ewus: {{ ewus }}</pre>
    <pre>ewusGuid: {{ ewusGuid }}</pre>
    <pre>trigger: {{ trigger }}</pre>
    <pre>patient: {{ patient }}</pre>
    <pre>me: {{ me }}</pre> -->
  </div>
</template>

<script>
import CommonMixins from '@/mixins/commons'
import ValidatorMixins from "@/mixins/dataValidators"
import { Action } from '@/store/config_actions';
import { Mutation } from '@/store/config_mutations';
import moment from 'moment'

export default {
  name: 'RegistrationDispatcher',
  mixins: [CommonMixins, ValidatorMixins],

  props: {
  },

  data: function () {
    return {
    }
  },

  mounted() {
  },

  beforeDestroy() {
  },

  computed: {

    ewus() { return this.$store.state.ewus.ewus },
    ewusGuid() { return this.$store.state.ewus.ewusGuid },
    ewusInsuranceStatus() { return this.$store.state.ewus.ewusInsuranceStatus },
    ewusPatient() { return this.$store.state.ewus.ewusPatient },

    ewusReservation() { return this.$store.state.ewus.ewusReservation },
    ewusReservationGuid() { return this.$store.state.ewus.ewusReservationGuid },
    ewusReservationInsuranceStatus() { return this.$store.state.ewus.ewusReservationInsuranceStatus },
    ewusReservationPatient() { return this.$store.state.ewus.ewusReservationPatient },

    patient() { return this.$store.state.registration.patient },

    me() { return this.$store.state.employee.me },

    trigger() { return this.$store.state.ewus.trigger }

  },

  watch: {

    ewusGuid() {
      if (this.ewusGuid) {
        this.update()
      }
    },

    ewusReservationGuid() {
      if (this.ewusReservationGuid) {
        this.updateReservation()
      }
    }
  },

  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    async update() {
      if (this.ewusGuid) {
        let ewusStatus = 0
        let ewusState = null
        let numberOfRequests = 0

        while (this.ewusGuid && this.mEwusStatusIsSerializable(ewusStatus) === false && numberOfRequests < 10) {
          await this.$store
            .dispatch(Action.EWUS_CHECK_RESPONSE, this.ewusGuid)
            .then((response) => {
              numberOfRequests += 1
              ewusState = response
              ewusStatus = response.ewusStatus
            })
            .catch((error) => {
              this.apiProblem(error)
            })

          if (this.mEwusStatusIsSerializable(ewusStatus) === false) {
            await this.sleep(1500)
          }
        }

        let patientInsurance = null
        if (this.mEwusStatusIsSerializable(ewusStatus) && !this.ewusInsuranceStatus && this.ewusPatient) {
          patientInsurance = await this.postPatientInsuranceStatus(ewusState)
        }

        if (patientInsurance) {
          this.$store.commit(Mutation.EWUS_SET_EWUS_INSURANCE_STATUS, patientInsurance)
        }

        if (this.ewusGuid) {
          this.$store.commit(Mutation.EWUS_SET_EWUS, ewusState)
        } else {
          this.$store.commit(Mutation.EWUS_SET_EWUS, null)
        }

        this.$store.commit(Mutation.EWUS_SET_EWUS_GUID, null)
        this.$store.commit(Mutation.EWUS_SET_EWUS_PENDING, false)
        this.$store.commit(Mutation.EWUS_TRIGGER_UP)
      }
    },

    async postPatientInsuranceStatus(ewusState) {
      
      const patientInsurancePayload = {
        domainId: this.me.domainId,
        patientId: this.ewusPatient.id,
        pesel: this.ewusPatient.pesel,
        date: moment().toDate(),
        ewusStatus: ewusState.ewusStatus,
        ewusDn: ewusState.ewusDN,
        ewusCode: ewusState.ewusCode,
        created: moment().toDate(),
        createdBy: this.me.id
      }

      let responsePatientInsurance = null
      await this.$store.dispatch(Action.PATIENT_POST_INSURANCE_STATUS, patientInsurancePayload)
          .then((response) => {
            responsePatientInsurance = response
          })
          .catch(error => {
            this.apiProblem(error)
          })
      
      return responsePatientInsurance
    },

    async updateReservation() {
      if (this.ewusReservationGuid) {
        let ewusStatus = 0
        let ewusState = null
        let numberOfRequests = 0

        while (this.mEwusStatusIsSerializable(ewusStatus) === false && numberOfRequests < 5) {
          await this.$store
            .dispatch(Action.EWUS_CHECK_RESPONSE, this.ewusReservationGuid)
            .then((response) => {
              numberOfRequests += 1
              ewusState = response
              ewusStatus = response.ewusStatus
            })
            .catch((error) => {
              this.apiProblem(error)
            })

          if (this.mEwusStatusIsSerializable(ewusStatus) === false) {
            await this.sleep(1250)
          }
        }

        let patientInsurance = null
        if (this.mEwusStatusIsSerializable(ewusStatus) && !this.ewusReservationInsuranceStatus && this.ewusReservationPatient) {
          patientInsurance = await this.postPatientReservationInsuranceStatus(ewusState)
        }

        if (patientInsurance) {
          this.$store.commit(Mutation.EWUS_SET_EWUS_INSURANCE_STATUS_RESERVATION, patientInsurance)
        }


        this.$store.commit(Mutation.EWUS_SET_EWUS_GUID_RESERVATION, null)
        this.$store.commit(Mutation.EWUS_SET_EWUS_RESERVATION, ewusState)
        this.$store.commit(Mutation.EWUS_SET_EWUS_PENDING_RESERVATION, false)
        this.$store.commit(Mutation.EWUS_TRIGGER_UP_RESERVATION)
      }
    },


    async postPatientReservationInsuranceStatus(ewusState) {

      const patientInsurancePayload = {
        domainId: this.me.domainId,
        patientId: this.ewusReservationPatient.id,
        pesel: this.ewusReservationPatient.pesel,
        date: moment().toDate(),
        ewusStatus: ewusState.ewusStatus,
        ewusDn: ewusState.ewusDN,
        ewusCode: ewusState.ewusCode,
        created: moment().toDate(),
        createdBy: this.me.id
      }

      let responsePatientInsurance = null
      await this.$store.dispatch(Action.PATIENT_POST_INSURANCE_STATUS, patientInsurancePayload)
          .then((response) => {
            responsePatientInsurance = response
          })
          .catch(error => {
            this.apiProblem(error)
          })
      
      return responsePatientInsurance
    },

  }
}
</script>

<style scoped lang="scss">
</style>
