import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

/** Validates user permits */
function validatePermits(user, checks) {
    let validated = false
    checks.forEach(role => {
        if (user.includes(role)) {
            validated = true
            return
        }
    });
    return validated
}

/** Validates user permits */
function revertedPermits(user, checks) {
    let validated = false
    checks.forEach(role => {
        if (user.includes(role)) {
            validated = true
            return
        }
    });
    return !validated
}

export default {
    namespaced: false,

    state: {
        /** Cache of workers. */
        all: [],
        me: null,
        focus: null,
        scheduledEmployees: [],
        employeeListFilters: null,

        permits: {
            admin: false,
            subadmin: false,
            uac2: false,
            schedule2editor: false,
            changelogView: false,
            changelogEdit: false,
            scheduleOld: false,
            visitEditor: false,
            ownDomainSwitch: false,
            registration: false,
            registrationB1: false,
            registrationEdekl: false,
            worktimeScheduler: false,
            worktimePatientPrintPESEL: false,
            patientIdentityEdit: false,
            callCenter: false,
            treatmentCoordination: false,
            managementRegion: false,
            managementCluster: false,
            eLearning: false,
            eLearningEditor: false,
            eLearningSuperEditor: false,
            patientPortal: false,
            mobi: false,
            mobiLimited: false,
            mobiStats: false,
            crm: false,
            orders: false,
            inventory: false,
            myAccount: false,
            worktimeManagement: false,
            humanResources: false,
            humanResourcesStats: false,
            crmIt: false,
            crmDigitmed: false,
            crmMaintenance: false,
            crmRodo: false,
            crmCustomerService: false,
            crmHr: false,
            crmPromo: false,
            superTypeRemove: false,
            administration: false,
            administrationContract: false,
            administrationStructure: false,
            administrationAgreementsEdit: false,
            predefinedP12Password: false,
            patientArchive: false,
            patientArchiveExaminations: false,
            patientArchiveSummary: false,

            boundlessSchedules: false,
            skipFacility: false,
            patientPrivileges: false,

            smsSending: false,

            zbpoz: false,
            jgp2: false,


            officePoz: false,
            officeNurse: false,
            officeMidwife: false,
            officePharma: false,
            officeAllergist: false,
            officeCardiologist: false,
            officeDermatologist: false,
            officeDiabetologist: false,
            officeDietician: false,
            officeEndocrinologist: false,
            officeGynecologist: false,
            officeNeurologist: false,
            officeParamedic: false,
            officeRheumatologist: false,
            officeSurgeonGeneral: false,
            officeSurgeonOncological: false,
            officeSurgeonTrauma: false,
            officeRehabilitation: false,
            officePhysiotherapy: false,
            officePediatrician: false,
            officePulmonologist: false,
            officePsychiatrist: false,
            officePsychologist: false,
            officeSpeechTherapist: false,
            officeTherapist: false,

            officeUrologist: false,
            officeOrthopaedist: false,

            officeInternalDiseases: false,
            officeLaryngologist: false,
            officeNephrologist: false,
            officeOncologist: false,

            gynecologistAssistant: false,
            officeAssistant: false,

            officeTest: false,

            workshopRadiology: false,
            workshopRadiologyWorktimeScheduler: false,
            workshopRadiologyWorktimeManagement: false,
            workshopRadiologyNoReferral: false,
            workshopRadiologyFullAccess: false,
            workshopRadiologyReadOnly: false,
            radiologyAdministrationBook: false,
            radiologyAdministrationMOBI: false,
            radiologyAdministrationMedDevicesFullAccess: false,
            radiologyAdministrationMedDevicesReadOnly: false,
            radiologyUncompleted: false,
            radiologyBurnCMJ: false,
            radiologyShowBirads: false,
            radiologyShowBlockedExam: false,
            radiologyDecideResultIssue: false,
            radiologyShowEbookVer2: false,
            workshopRehabilitation: false,
            workshopDiagnostics: false,
            workshopCare: false,
            collectionPoint: false,
            collectionPointNoCitoSend: false,
            laboratory: false,
            laboratoryRecive: false,
            laboratoryManagement: false,
            officeEndoscopy: false,
            viralLaboratory: false,
            labLockedViral: false,
            labLockedCyto: false,
            viralCollectionPoint: false,
            radiologyAdministration: false,
            emplyeeEvents: false,

            histopathologyCollectionPoint: false,
            histopathologyPathologist: false,
            histopathologyAdministrator: false,

            cytologyAnalysis: false,
            cytologyRescreeningLowRisk: false,
            cytologyRescreeningHighRisk: false,
            cytologyStandsManagement: false,
            cytologyAnalysisDrafts: false,

            administrationPopulationManagement: false,

            administrationPatientCoordination: false,

            labMiniCytology: false,
            labMiniCytologyAssistant: false,
            labMiniCytologyAdministration: false,

            eReferralBook: false,

            nfzSettlements: false,
            serviceLimiting: false,
            registrationNfzService: false,
            sendZmFor40: false,

            prices: false,

            tpk: false,
            tpkMaster: false,

            testComps: false,
            tester: false,
            testerScaner: false,

            drugControlEdit: false,
            drugControlApprove: false,

            myIkz: false,
            shorterAppointments: false,

            ikzPharma: false,

            apkolceReferralDataNotRequired: false,
            apkolceDataNotRequired: false,
            diloDataNotRequired: false,

            settlementsDeclarations: false,
            settlementsOrdersAndTickets: false,
            settlementsServices: false,
            settlementsRegister: false,
            settlementsContractors: false,
            settlementsCashRegister: false,
            settlementsIKZ: false,
            settlementsSummary: false,
            settlementsReports: false,

            settlementsInvoicesPrint: false,
            settlementsLumpSumInvoices: false,

            projectOsteoporosis: false,
            project40Plus: false,
            ipom: false,
            midwifeOfficeSchedule: false,
            midwifeEducations: false,

            nurseIcdsChoice: false,
            nurseDashboardShowIpom: false,
            hpvSolab: false,

            removeLabSamples: false,
            printLabSamplesLabelsFromLabSearch: false,

            test700: false,
            test701: false,

            userManual: false,
            nursePrescriptions: false,
            newExaminationsTab: false,

            testP12PassHidden: false,
            midwifeCollectionPointHidden: false,
            apkolceShowBaner: false,
            verifiedFilePanel: false,

            experimentalPOZOffice: false
        },

        ikz: [],
    },

    getters: {
        myRoles: state => {
            if (state.me != null) return state.me.roles
            else return []
        },

        myPermits(state) {
            return state.permits
        }
    },

    mutations: {


        /** Modify worker (Actually replace worker info with updated) */
        [Mutation.EMPLOYEE_SET_ME](state, worker) {
            state.me = worker
            let myRoles = worker.roles

            // Check if admin
            let admin = myRoles.includes(999)
            let subadmin = myRoles.includes(997)

            state.permits.admin = admin
            state.permits.subadmin = subadmin || admin

            state.permits.uac2 = validatePermits(myRoles, [996])
            state.permits.schedule2editor = validatePermits(myRoles, [291])
            state.permits.scheduleOld = validatePermits(myRoles, [292])

            state.permits.changelogView = admin || subadmin || validatePermits(myRoles, [898])
            state.permits.changelogEdit = admin || validatePermits(myRoles, [899])

            state.permits.visitEditor = validatePermits(myRoles, [295])

            state.permits.patientPrivileges = validatePermits(myRoles, [901])

            state.permits.smsSending = admin || subadmin || validatePermits(myRoles, [229])
            state.permits.ownDomainSwitch = admin || subadmin || validatePermits(myRoles, [299])

            state.permits.patientIdentityEdit = validatePermits(myRoles, [72])
            state.permits.worktimeScheduler = admin || subadmin || validatePermits(myRoles, [73])
            state.permits.predefinedP12Password = admin || subadmin || validatePermits(myRoles, [50, 51])
            state.permits.worktimePatientPrintPESEL = validatePermits(myRoles, [78])
            state.permits.registration = admin || subadmin || validatePermits(myRoles, [70, 10, 11, 12, 20, 21])
            state.permits.registrationB1 = admin || subadmin || validatePermits(myRoles, [77])
            state.permits.callCenter = admin || subadmin || validatePermits(myRoles, [71])
            state.permits.treatmentCoordination = admin || subadmin || validatePermits(myRoles, [])
            state.permits.managementRegion = admin || subadmin || validatePermits(myRoles, [221])
            state.permits.managementCluster = admin || subadmin || validatePermits(myRoles, [222])
            state.permits.eLearning = revertedPermits(myRoles, [550, 306])
            state.permits.eLearningEditor = admin || subadmin || validatePermits(myRoles, [303, 304])
            state.permits.eLearningSuperEditor = admin || subadmin || validatePermits(myRoles, [304])
            state.permits.patientPortal = admin || subadmin || validatePermits(myRoles, [329])
            state.permits.mobi = admin || subadmin || validatePermits(myRoles, [132])
            state.permits.mobiLimited = validatePermits(myRoles, [133])
            state.permits.mobiStats = admin || subadmin || validatePermits(myRoles, [136])
            state.permits.orders = admin || subadmin || validatePermits(myRoles, [205])
            state.permits.inventory = admin || subadmin || validatePermits(myRoles, [306])
            state.permits.myAccount = revertedPermits(myRoles, [550])
            state.permits.boundlessSchedules = admin || subadmin || validatePermits(myRoles, [308])
            state.permits.skipFacility = validatePermits(myRoles, [324])
            state.permits.myIkz = admin || subadmin || validatePermits(myRoles, [326])
            state.permits.shorterAppointments = validatePermits(myRoles, [327])
            state.permits.ignoreTimelessLimit = validatePermits(myRoles, [328])
            state.permits.registrationEdekl = admin || subadmin || validatePermits(myRoles, [471])

            state.permits.patientArchive = admin || subadmin || validatePermits(myRoles, [74])
            state.permits.patientArchiveExaminations = admin || subadmin || validatePermits(myRoles, [75])
            state.permits.patientArchiveSummary = admin || subadmin || validatePermits(myRoles, [76])
            state.permits.patientArchiveVisitsWorker = validatePermits(myRoles, [79])

            state.permits.worktimeManagement = admin || subadmin || validatePermits(myRoles, [207])
            state.permits.humanResources = admin || subadmin || validatePermits(myRoles, [200])
            state.permits.humanResourcesStats = admin || subadmin || validatePermits(myRoles, [470])
            state.permits.administration = admin || subadmin || validatePermits(myRoles, [210])
            state.permits.administrationContract = admin || subadmin || validatePermits(myRoles, [209])
            state.permits.administrationStructure = admin || subadmin || validatePermits(myRoles, [208])
            state.permits.administrationAgreementsEdit = admin || subadmin || validatePermits(myRoles, [211])
            state.permits.nfzSettlements = admin || subadmin || validatePermits(myRoles, [213])
            state.permits.nfzSettlementsV2 = admin || subadmin || validatePermits(myRoles, [213])
            state.permits.prices = admin || subadmin || validatePermits(myRoles, [214])

            state.permits.serviceLimiting = validatePermits(myRoles, [472])
            state.permits.registrationNfzService = validatePermits(myRoles, [473])
            state.permits.sendZmFor40 = validatePermits(myRoles, [474])

            state.permits.diagnoseV1 = validatePermits(myRoles, [475])
            state.permits.jgp2 = validatePermits(myRoles, [476])
            state.permits.vaccinationV2 = validatePermits(myRoles, [477])

            state.permits.officePoz = admin || subadmin || validatePermits(myRoles, [10, 11, 20, 21])
            state.permits.officeIPOM = admin || subadmin || validatePermits(myRoles, [33])
            state.permits.officeEndoscopy = admin || subadmin || validatePermits(myRoles, [])
            state.permits.officeNurse = admin || subadmin || validatePermits(myRoles, [40, 41, 130])
            state.permits.officeMidwife = admin || subadmin || validatePermits(myRoles, [50, 51])
            state.permits.officePharma = admin || subadmin || validatePermits(myRoles, [180, 181])
            state.permits.officeAllergist = admin || subadmin || validatePermits(myRoles, [20, 22])
            state.permits.officeCardiologist = admin || subadmin || validatePermits(myRoles, [20, 24])
            state.permits.officeDermatologist = admin || subadmin || validatePermits(myRoles, [20, 25])
            state.permits.officeDiabetologist = admin || subadmin || validatePermits(myRoles, [])
            state.permits.officeDietician = admin || subadmin || validatePermits(myRoles, [101])
            state.permits.officeEndocrinologist = admin || subadmin || validatePermits(myRoles, [20, 30])
            state.permits.officeGynecologist = admin || subadmin || validatePermits(myRoles, [31])
            state.permits.officeNeurologist = admin || subadmin || validatePermits(myRoles, [20, 28])
            state.permits.officeParamedic = admin || subadmin || validatePermits(myRoles, [])
            state.permits.officeRheumatologist = admin || subadmin || validatePermits(myRoles, [32])
            state.permits.officeSurgeonGeneral = admin || subadmin || validatePermits(myRoles, [])
            state.permits.officeSurgeonOncological = admin || subadmin || validatePermits(myRoles, [])
            state.permits.officeSurgeonTrauma = admin || subadmin || validatePermits(myRoles, [])
            state.permits.officeRehabilitation = admin || subadmin || validatePermits(myRoles, [60])
            state.permits.officePhysiotherapy = admin || subadmin || validatePermits(myRoles, [60])
            state.permits.officePediatrician = admin || subadmin || validatePermits(myRoles, [10, 11])
            state.permits.officePulmonologist = admin || subadmin
            state.permits.officePsychiatrist = admin || subadmin
            state.permits.officePsychologist = admin || subadmin || validatePermits(myRoles, [102, 103])
            state.permits.officeSpeechTherapist = admin || subadmin || validatePermits(myRoles, [107])
            state.permits.officeTherapist = admin || subadmin || validatePermits(myRoles, [105, 106])
            state.permits.officeUrologist = admin || subadmin || validatePermits(myRoles, [20, 26])
            state.permits.officeRadiology = admin || subadmin || validatePermits(myRoles, [152])
            state.permits.officeOrthopaedist = admin || subadmin || validatePermits(myRoles, [20, 27])
            state.permits.officeInternalDiseases = admin || subadmin
            state.permits.officeLaryngologist = admin || subadmin || validatePermits(myRoles, [20, 23])
            state.permits.officeNephrologist = admin || subadmin || validatePermits(myRoles, [20, 29])
            state.permits.officeOncologist = admin || subadmin
            state.permits.gynecologistAssistant = admin || subadmin || validatePermits(myRoles, [81])
            state.permits.officeAssistant = admin || subadmin || validatePermits(myRoles, [82])

            state.permits.workshopRadiology = admin || subadmin || validatePermits(myRoles, [151])
            state.permits.workshopRadiologyNoReferral = validatePermits(myRoles, [167])
            state.permits.workshopRadiologyWorktimeScheduler = admin || subadmin || validatePermits(myRoles, [168])
            state.permits.workshopRadiologyWorktimeManagement = admin || subadmin || validatePermits(myRoles, [169])
            state.permits.workshopRehabilitation = admin || subadmin || validatePermits(myRoles, [/*60, 61, 62,*/ 500])
            state.permits.workshopDiagnostics = admin || subadmin || validatePermits(myRoles, [40, 41, 50, 51, 151])
            state.permits.workshopCare = admin || subadmin || validatePermits(myRoles, [101, 102, 103, 105, 108])

            state.permits.workshopRadiologyFullAccess = admin || subadmin || validatePermits(myRoles, [170])
            state.permits.workshopRadiologyReadOnly = admin || subadmin || validatePermits(myRoles, [171])
            state.permits.radiologyAdministrationBook = admin || subadmin || validatePermits(myRoles, [172])
            state.permits.radiologyAdministrationMOBI = admin || subadmin || validatePermits(myRoles, [173])
            state.permits.radiologyAdministrationMedDevicesFullAccess = admin || subadmin || validatePermits(myRoles, [174])
            state.permits.radiologyAdministrationMedDevicesReadOnly = admin || subadmin || validatePermits(myRoles, [175])
            state.permits.radiologyAdministrationStats = admin || subadmin || validatePermits(myRoles, [176])
            state.permits.radiologyRegistration = admin || subadmin || validatePermits(myRoles, [177])
            state.permits.radiologyUncompleted = validatePermits(myRoles, [178])
            state.permits.radiologyBurnCMJ = validatePermits(myRoles, [335])
            state.permits.radiologyShowBlockedExam = validatePermits(myRoles, [336])
            state.permits.radiologyShowBirads = validatePermits(myRoles, [337])

            state.permits.collectionPointNoCitoSend = validatePermits(myRoles, [338])

            state.permits.radiologyDecideResultIssue = validatePermits(myRoles, [339])
            state.permits.radiologyShowEbookVer2 = validatePermits(myRoles, [340])




            state.permits.collectionPoint = admin || subadmin || validatePermits(myRoles, [40, 41, 50, 51, 130, 305])
            state.permits.laboratory = admin || subadmin
            state.permits.viralLaboratory = admin || subadmin || validatePermits(myRoles, [150])
            state.permits.viralCollectionPoint = admin || subadmin || validatePermits(myRoles, [153])
            state.permits.laboratoryRecive = admin || subadmin || validatePermits(myRoles, [150, 550])
            state.permits.laboratoryManagement = admin || subadmin || validatePermits(myRoles, [155])
            state.permits.labLockedViral = validatePermits(myRoles, [158])
            state.permits.labLockedCyto = validatePermits(myRoles, [159])
            state.permits.labCytologyManagement = admin || subadmin || validatePermits(myRoles, [160])
            state.permits.radiologyAdministration = admin || subadmin || validatePermits(myRoles, [161])

            state.permits.labMiniCytology = validatePermits(myRoles, [163])
            state.permits.labMiniCytologyAssistant = validatePermits(myRoles, [164])
            state.permits.labMiniCytologyAdministration = validatePermits(myRoles, [179])

            state.permits.cytologyAnalysis = admin || subadmin || validatePermits(myRoles, [154])
            state.permits.cytologyRescreeningLowRisk = validatePermits(myRoles, [156])
            state.permits.cytologyRescreeningHighRisk = validatePermits(myRoles, [162])
            state.permits.cytologyStandsManagement = admin || subadmin || validatePermits(myRoles, [165])
            state.permits.cytologyAnalysisDrafts = validatePermits(myRoles, [166])

            state.permits.administrationPopulationManagement = validatePermits(myRoles, [227])

            state.permits.administrationPatientCoordination = admin || subadmin || validatePermits(myRoles, [230])

            state.permits.histopathologyCollectionPoint = admin || subadmin || validatePermits(myRoles, [190])
            state.permits.histopathologyPathologist = admin || subadmin || validatePermits(myRoles, [191])
            state.permits.histopathologyAdministrator = admin || subadmin || validatePermits(myRoles, [192])

            state.permits.officeTest = admin || subadmin || validatePermits(myRoles, [998])
            state.permits.crm = admin || subadmin || validatePermits(myRoles, [307, 309])
            state.permits.crmIt = admin || subadmin || validatePermits(myRoles, [309, 310])
            state.permits.crmDigitmed = admin || subadmin || validatePermits(myRoles, [311, 312])
            state.permits.crmMaintenance = admin || subadmin || validatePermits(myRoles, [313, 314])
            state.permits.crmRodo = admin || subadmin || validatePermits(myRoles, [315, 316])
            state.permits.crmCustomerService = admin || subadmin || validatePermits(myRoles, [317, 318])
            state.permits.crmHr = admin || subadmin || validatePermits(myRoles, [319, 320])
            state.permits.crmPromo = admin || subadmin || validatePermits(myRoles, [321, 322])
            state.permits.superTypeRemove = admin || subadmin || validatePermits(myRoles, [323])
            state.permits.emplyeeEvents = admin || subadmin || validatePermits(myRoles, [212])

            state.permits.tpk = admin || validatePermits(myRoles, [601, 602, 603, 604, 605, 606, 607])
            state.permits.tpkMaster = admin || validatePermits(myRoles, [604, 605, 606])

            state.permits.testComps = admin || subadmin || validatePermits(myRoles, [667])
            state.permits.tester = admin || subadmin || validatePermits(myRoles, [668])
            state.permits.testerScaner = validatePermits(myRoles, [669])


            state.permits.mobiVehicleEdit = admin || subadmin || validatePermits(myRoles, [134])
            state.permits.mobiVehicleDelete = admin || subadmin || validatePermits(myRoles, [135])

            state.permits.eReferralBook = admin || subadmin || validatePermits(myRoles, [325])

            state.permits.drugControlEdit = validatePermits(myRoles, [400])
            state.permits.drugControlApprove = validatePermits(myRoles, [401])

            state.permits.ikzPharma = validatePermits(myRoles, [360])
            state.permits.ikzVersion2 = validatePermits(myRoles, [361])
            state.permits.ikzVersion2Stats = validatePermits(myRoles, [362])
            state.permits.ikzManagerEdit = admin || subadmin || validatePermits(myRoles, [363])

            state.permits.apkolceReferralDataNotRequired = validatePermits(myRoles, [332])
            state.permits.apkolceDataNotRequired = validatePermits(myRoles, [333])
            state.permits.diloDataNotRequired = validatePermits(myRoles, [334])

            state.permits.settlementsDeclarations = admin || subadmin || validatePermits(myRoles, [215])
            state.permits.settlementsOrdersAndTickets = admin || subadmin || validatePermits(myRoles, [216])
            state.permits.settlementsServices = admin || subadmin || validatePermits(myRoles, [217])
            state.permits.settlementsRegister = validatePermits(myRoles, [341])
            state.permits.settlementsContractors = admin || subadmin || validatePermits(myRoles, [218])
            state.permits.settlementsCashRegister = admin || subadmin || validatePermits(myRoles, [219])
            state.permits.settlementsIKZ = admin || subadmin || validatePermits(myRoles, [226])
            state.permits.settlementsSummary = admin || subadmin || validatePermits(myRoles, [228])
            state.permits.settlementsReports = admin || subadmin || validatePermits(myRoles, [233])
            state.permits.settlementsLumpSumInvoices = admin || subadmin || validatePermits(myRoles, [231])
            state.permits.settlementsInvoicesPrint = validatePermits(myRoles, [232])

            state.permits.projectManagement = admin || subadmin || validatePermits(myRoles, [620])
            state.permits.projectOsteoporosis = admin || subadmin || validatePermits(myRoles, [621])
            state.permits.projectTomography = admin || subadmin || validatePermits(myRoles, [622])
            state.permits.project40Plus = admin || subadmin || validatePermits(myRoles, [623])

            state.permits.zbpoz = admin || subadmin || validatePermits(myRoles, [251])
            state.permits.lioczCreate = admin || subadmin || validatePermits(myRoles, [213, 250])
            state.permits.ipom = admin || subadmin || validatePermits(myRoles, [111])
            state.permits.ipomVer2 = validatePermits(myRoles, [112])

            state.permits.midwifeOfficeSchedule = validatePermits(myRoles, [185])
            state.permits.midwifeEducations = validatePermits(myRoles, [186])

            state.permits.nurseIcdsChoice = validatePermits(myRoles, [43])
            state.permits.nurseDashboardShowIpom = validatePermits(myRoles, [44])

            state.permits.hpvSolab = validatePermits(myRoles, [187])

            state.permits.test700 = admin || subadmin || validatePermits(myRoles, [700])
            state.permits.test701 = validatePermits(myRoles, [701])

            state.permits.removeLabSamples = validatePermits(myRoles, [580])

            state.permits.printLabSamplesLabelsFromLabSearch = validatePermits(myRoles, [581])

            state.permits.userManual = validatePermits(myRoles, [490])

            state.permits.nursePrescriptions = validatePermits(myRoles, [485])
            state.permits.newExaminationsTab = validatePermits(myRoles, [478])

            state.permits.testP12PassHidden = validatePermits(myRoles, [491])

            state.permits.midwifeCollectionPointHidden = validatePermits(myRoles, [486])

            state.permits.apkolceShowBaner = validatePermits(myRoles, [342]) //additional, see in apkolceBaner for more information
            state.permits.verifiedFilePanel = admin || validatePermits(myRoles, [343])

            state.permits.experimentalPOZOffice = validatePermits(myRoles, [702])


        },

        [Mutation.EMPLOYEE_CLEAR](currentState) {
            currentState.all = null
            currentState.me = null
        },

        [Mutation.EMPLOYEE_SET_FOCUS](currentState, employee) {
            currentState.focus = employee
        },

        /** Load all workers to app cache */
        [Mutation.EMPLOYEE_SET_ALL](state, workers) {

            let tmp = []

            workers.forEach(e => {
                let worker = e
                worker.name = `${e.lastName} ${e.firstName}`

                if (state.me) {
                    worker.isOtherDomain = worker.domainId != state.me.domainId
                }

                tmp.push(worker)
            });

            state.all = tmp.sort((a, b) => { return a.lastName < b.lastName ? -1 : 1 })
        },

        /** Load all workers to app cache */
        [Mutation.EMPLOYEE_SET_SCHEDULED](state, workers) {

            let tmp = []

            workers.forEach(e => {
                let worker = e
                worker.name = `${e.lastName} ${e.firstName}`
                tmp.push(worker)
            });

            state.scheduledEmployees = tmp.sort((a, b) => { return a.lastName < b.lastName ? -1 : 1 })
        },

        /** Add worker to app cache */
        [Mutation.EMPLOYEE_PUSH](state, worker) {
            if (state.all != null) {
                state.all.push(worker)
            }
        },

        /** Modify worker (Actually replace worker info with updated) */
        [Mutation.EMPLOYEE_PUT](state, worker) {
            let indexOfWorker = state.all.findIndex(u => u.id === worker.id)
            if (indexOfWorker > -1) state.all[indexOfWorker] = worker
        },

        /** Remove worker from cache */
        [Mutation.EMPLOYEE_DELETE](state, workerId) {
            let indexOfWorker = state.all.findIndex(u => u.id === workerId)
            if (indexOfWorker > -1) state.all.splice(indexOfWorker, 1)
        },


        [Mutation.EMPLOYEE_LIST_FILTERS_SET](state, filters) {
            state.employeeListFilters = filters
        },

        [Mutation.EMPLOYEE_LIST_FILTERS_CLEAR](state) {
            state.employeeListFilters = null
        },

        [Mutation.EMPLOYEE_SET_IKZ](state, payload) {
            state.ikz = payload
        }
    },

    actions: {
        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.EMPLOYEE_CREATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_ADD,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.EMPLOYEE_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.EMPLOYEE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_SET_ALL, response.data)
                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get own worker data. Uses GET request without parameter */
        [Action.EMPLOYEE_GET_ME](context) {
            return new Promise((resolve, reject) => {
                if (context.rootState.identity.session) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    if (token != null) {
                        axios({
                            method: 'get',
                            url: Apis.EMPLOYEE_GET_ME,
                            headers: { Authorization: "Bearer " + token }
                        }).then(response => {
                            context.commit(Mutation.EMPLOYEE_SET_ME, response.data)
                            context.dispatch(Action.REGISTRATION_RESET)
                            resolve(response.data);
                        }).catch(error => { reject(error) });
                    } else reject('401')
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.EMPLOYEE_GET](context, id) {
            return new Promise((resolve, reject) => {
                let existing = null

                if (context.state.all) {
                    existing = context.state.all.find(x => x.id == id)
                }

                if (existing) {
                    resolve({ data: existing });
                }
                else {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    if (token != null) {
                        axios({
                            method: 'get',
                            url: Apis.EMPLOYEE_GET.replace('{id}', id),
                            headers: { Authorization: "Bearer " + token }
                        }).then(response => {
                            resolve(response);
                        }).catch(error => { reject(error) });
                    } else reject('401')
                }
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.EMPLOYEE_GET_BY_POSITION](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_GET_ALL_BY_POSITION.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_SET_SCHEDULED, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.EMPLOYEE_GET_BY_MANY_POSITIONS](context, positions) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_GET_ALL_BY_POSITIONS,
                        data: positions,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.EMPLOYEE_SET_SCHEDULED, response.data)
                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.EMPLOYEE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_DELETE, id)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.EMPLOYEE_PUT](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_PUT.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_PUT, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.EMPLOYEE_PUT_EMAIL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_PUT_EMAIL.replace('{id}', payload.id),
                        data: payload.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_PUT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.EMPLOYEE_CHANGE_MY_DOMAIN](context, target) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_CHANGE_MY_DOMAIN.replace('{target}', target),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_PUT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.EMPLOYEE_JOBS](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;

                let payload = {
                    clinics: worker.clinics,
                    activeBonusRoles: worker.activeBonusRoles,
                    specializations: worker.specializations,
                    facilities: worker.facilities,
                    positions: worker.activeBonusRoles,
                    internalCourses: worker.internalCourses,
                    externalCourses: worker.externalCourses,
                    certificates: worker.certificates,
                    specialPermissions: worker.specialPermissions,
                    otherDomainAccess: worker.otherDomainAccess
                }

                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_JOBS.replace('{id}', worker.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_PUT, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },



        [Action.GDPR_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.GDPR_PUT.replace('{id}', payload.workerId),
                        data: payload.gdpr,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_SET_ME, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_CERTIFICATE_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('type', form.type)
                    payload.append('name', form.name)
                    payload.append('description', form.description)
                    payload.append('file', form.file)
                    payload.append('employee', form.employee)

                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_CERTIFICATE_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_CERTIFICATE_GET_BY_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_CERTIFICATE_GET_BY_WORKER.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.EMPLOYEE_CERTIFICATE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_CERTIFICATE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_CERTIFICATE_DOWNLOAD](context, fileId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.EMPLOYEE_CERTIFICATE_DOWNLOAD.replace('{id}', fileId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_FILE_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('type', form.type)
                    payload.append('name', form.name)
                    payload.append('description', form.description)
                    payload.append('file', form.file)
                    payload.append('employee', form.employee)

                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_FILE_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_FILE_GET_BY_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_FILE_GET_BY_WORKER.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },




        [Action.EMPLOYEE_GET_CHANGES](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_GET_CHANGES.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_GET_CHANGE_DETAILS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_GET_CHANGE_DETAILS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },




        /** Attempt worker delete. Uses DELETE request */
        [Action.EMPLOYEE_FILE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_FILE_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_FILE_DOWNLOAD](context, fileId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.EMPLOYEE_FILE_DOWNLOAD.replace('{id}', fileId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_CUSTOM_DRUG_GET_BY_EMPLOYEE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_CUSTOM_DRUG_GET_BY_EMPLOYEE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_CUSTOM_DRUG_POST](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_CUSTOM_DRUG_POST,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_CUSTOM_DRUG_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_CUSTOM_DRUG_PUT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_CUSTOM_DRUG_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_CUSTOM_DRUG_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

    }
}