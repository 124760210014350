import axios from 'axios'
import { Apis } from '../config_apis'
import { Action } from '../config_actions'

export default {

    state: {

    },

    getters: {},

    mutations: {

    },

    actions: {
        [Action.FISCAL_PRINTER_CREATE](context, fiscal) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FISCAL_PRINTER_CREATE,
                        data: fiscal,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.FISCAL_PRINTER_UPDATE](context, fiscal) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FISCAL_PRINTER_UPDATE.replace('{id}', fiscal.id),
                        data: fiscal,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        /** Attempt get all printers. Uses GET request without parameter */
        [Action.FISCAL_PRINTER_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FISCAL_PRINTER_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        /** Attempt get one printer. Uses GET request without parameter */
        [Action.FISCAL_PRINTER_GET](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FISCAL_PRINTER_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        /** Attempt worker edit. Uses Delete request with id and requires worker data object (check config_apis.js)*/
        [Action.FISCAL_PRINTER_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FISCAL_PRINTER_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

    }
}